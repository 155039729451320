import * as React from 'react';
import { Field, Form, Formik, FormikProps } from 'formik';
import { Button, Callout, H5, Intent, Text } from '@blueprintjs/core';
import { visit } from 'turbolinks';

import { axiosClient } from '../helpers';
import { renderNumericField, renderSelectField, renderTextAreaField, renderTextField } from './form';

import { License, LicenseType, Server } from '../models';
import { SelectOption } from './Select';

const LEGACY_TEMPLATE = `{` +
  `"company":"[company_name]",` +
  `"company_id":"[company_id]",` +
  `"support_expiration":"2020-09-14T18:23:16.142Z",` +
  `"license_expiration":"2021-09-14T18:23:16.142Z",` +
  `"license_type":"[license_type]",` +
  `"agent_limits":{` +
  `"max_agents":"[max_agents]",` +
  `"software":"[num_software_agents]",` +
  `"faste":"[num_faste_agents]",` +
  `"external":"[num_external_agents]",` +
  `"gige":"[num_gige_agents]",` +
  `"virtual":"[num_virtual_agents]",` +
  `"wireless":"[num_wireless_agents]",` +
  `"cumulus":"[num_cumulus_agents]"` +
  `},` +
  `"hw_id":"[hw_id]",` +
  `"restrictions":{` +
  `"max_targets":"[max_targets]"` +
  `}` +
  `}`;

const LICENSE_TYPE_OPTIONS: SelectOption[] = [
  { label: 'enterprise', value: LicenseType.ENTERPRISE },
  { label: 'trial', value: LicenseType.TRIAL },
  { label: 'legacy', value: LicenseType.LEGACY }
];

type CreateUpdateLicenseForm = Pick<License,
  'license_type' | 'hardware_id' | 'expires_at' | 'agent_licenses' | 'server_id'
> & {
  legacy_template?: string;
};

export interface CreateUpdateLicenseProps {
  license?: License;
  server: Server;
}

export interface CreateUpdateLicenseState {
  error?: boolean;
}

export class CreateUpdateLicense extends React.PureComponent<CreateUpdateLicenseProps, CreateUpdateLicenseState> {
  state: CreateUpdateLicenseState = {
    error: undefined
  };

  render(): React.ReactNode {
    const { license, server } = this.props;

    const initialValues: CreateUpdateLicenseForm = {
      license_type: !!license ? license.license_type : LicenseType.ENTERPRISE,
      server_id: server.id,
      hardware_id: !!license ? license.hardware_id : '',
      expires_at: !!license
        ? license.expires_at
        : new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString(),
      agent_licenses: !!license ? license.agent_licenses : {
        network_agents: { agent_limit: 0, target_test_limit: 50, scheduled_test_limit: -1 },
        remote_worker_agents: { agent_limit: 0, target_test_limit: 25, scheduled_test_limit: 3 }
      },
      legacy_template: license?.license_type === LicenseType.LEGACY
        ? JSON.stringify(license.agent_licenses)
        : LEGACY_TEMPLATE
    };

    return (
      <Formik
        onSubmit={this.onCreateUpdateLicense}
        render={this.renderForm}
        initialValues={initialValues}
      />
    );
  }

  private renderForm = (props: FormikProps<CreateUpdateLicenseForm>): React.ReactNode => {
    return (
      <Form className='create-customer'>
        {this.renderHeader()}
        {this.renderError()}
        <div className='create-customer-fields-container'>
          <Field
            name={'license_type'}
            render={renderSelectField(
              LICENSE_TYPE_OPTIONS,
              'License type',
              undefined,
              undefined,
              !this.canUpdateAgentLicense()
             )}
          />
           <Field
            name='hardware_id'
            render={renderTextField('Hardware ID')}
          />
          <Field
            name='expires_at'
            render={renderTextField(
              'Expiration Date',
              undefined,
              undefined,
              undefined,
              !this.canUpdateAgentLicense()
            )}
          />
          {props.values.license_type === LicenseType.LEGACY
            ? (
              <div>
                <Field
                  name='legacy_template'
                  render={renderTextAreaField(
                    'Legacy License',
                    undefined,
                    undefined,
                    !this.canUpdateAgentLicense()
                  )}
                />
              </div>
            ) : (
              <div>
                <Text className='margin-bottom'>Network Agents</Text>
                <Field
                  name='agent_licenses.network_agents.agent_limit'
                  render={renderNumericField(
                    'Agent Limit',
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    !this.canUpdateAgentLicense()
                  )}
                />
                <Field
                  name='agent_licenses.network_agents.target_test_limit'
                  render={renderNumericField(
                    'Target Limit',
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    !this.canUpdateAgentLicense()
                  )}
                />
                <Field
                  name='agent_licenses.network_agents.scheduled_test_limit'
                  render={renderNumericField(
                    'Scheduled Test Limit',
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    !this.canUpdateAgentLicense()
                  )}
                />
                <Text className='margin-bottom'>Remote Worker Agents</Text>
                <Field
                  name='agent_licenses.remote_worker_agents.agent_limit'
                  render={renderNumericField(
                    'Agent Limit',
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    !this.canUpdateAgentLicense()
                  )}
                />
                <Field
                  name='agent_licenses.remote_worker_agents.target_test_limit'
                  render={renderNumericField(
                    'Target Limit',
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    !this.canUpdateAgentLicense()
                  )}
                />
                <Field
                  name='agent_licenses.remote_worker_agents.scheduled_test_limit'
                  render={renderNumericField(
                    'Scheduled Test Limit',
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    !this.canUpdateAgentLicense()
                  )}
                />
              </div>
            )
          }
        </div>
      </Form>
    );
  }

  private renderHeader = (): React.ReactNode => {
    const { server } = this.props;
    const buttonTitle = this.props.license ? 'Update License' : ' Create License';

    return (
      <div className='create-header editable-header'>
        <a href={`/servers/${server.id}`} className='button-link'>Cancel</a>
        <H5>{buttonTitle}</H5>
        <Button
          text={buttonTitle}
          intent={Intent.PRIMARY}
          type='submit'
        />
      </div>
    );
  }

  private renderError = (): React.ReactNode => {
    const { error } = this.state;

    return !!error && (
      <Callout
        intent={Intent.DANGER}
        className='margin-bottom-large'
      >
        Something went wrong while processing your request...
      </Callout>
    );
  }

  private onCreateUpdateLicense = async (values: CreateUpdateLicenseForm) => {
    const { license, server } = this.props;

    const payload = { ...values };
    if (values.license_type === LicenseType.LEGACY) {
      payload.agent_licenses = { ...JSON.parse(values.legacy_template || '') };
    } else {
      const { network_agents, remote_worker_agents } = values.agent_licenses;
      payload.agent_licenses = {
        network_agents: {
          agent_limit: +network_agents.agent_limit,
          target_test_limit: +network_agents.target_test_limit,
          scheduled_test_limit: +network_agents.scheduled_test_limit
        },
        remote_worker_agents: {
          agent_limit: +remote_worker_agents.agent_limit,
          target_test_limit: +remote_worker_agents.target_test_limit,
          scheduled_test_limit: +remote_worker_agents.scheduled_test_limit
        }
      };
    }
    delete payload.legacy_template;

    try {
      if (!!license) {
        await axiosClient.put(`/servers/${server.id}/licenses/${license.id}.json`, { license: payload });
      } else {
        await axiosClient.post(`/servers/${server.id}/licenses.json`, { license: payload });
      }

      this.setState({ error: false });
      visit(`/servers/${server.id}?tab=licenses`);
    } catch (error) {
      this.setState({ error: true });
      console.log('Something went wrong with your request.');
    }
  }

  private canUpdateAgentLicense = (): boolean => {
    const { license } = this.props;

    if (license !== undefined && license?.created_at) {
      const currentDate = new Date().getTime();
      const dateCreated = new Date(license.created_at).getTime();
      const dateThreshold = dateCreated + (5 * 86400000);

      return currentDate > dateThreshold;
    }

    return true;
  }
}
