import { FieldProps } from 'formik';
import * as React from 'react';
import { FormGroup, InputGroup, Intent } from '@blueprintjs/core';

export const renderTextField = (
  label?: string,
  helpText?: string,
  type?: string,
  labelAbove?: boolean,
  disabled?: boolean,
  callback?: (valueAsString: string) => void
) => (props: FieldProps): React.ReactNode => {
  const { field, form } = props;
  const { setFieldValue, setFieldTouched, errors, touched } = form;
  const setFieldValueFn = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFieldValue(field.name, value);
    setFieldTouched(field.name);

    if (callback) {
      callback(value);
    }
  };

  const error = touched[field.name] && errors[field.name];

  return (
    <FormGroup
      label={label ? `${label}:` : ''}
      inline={!labelAbove}
      intent={Intent.NONE}
      className={`${labelAbove ? 'margin-right' : 'form-group'} ${error ? 'error' : ''}`}
    >
      <InputGroup
        {...field}
        intent={Intent.NONE}
        className='input'
        type={type ? type : 'text'}
        disabled={disabled}
        onChange={setFieldValueFn}
      />
      {!!helpText && <div className='input-helper'>{helpText}</div>}
      {!!error && <div className='input-error'>{errors[field.name]}</div>}
    </FormGroup>
  );
};
