import React, { PureComponent } from 'react';
import { Intent, IToastProps, Position, Toast, Toaster } from '@blueprintjs/core';
import { IconName, IconNames } from '@blueprintjs/icons';
import { visit } from 'turbolinks';

const { createConsumer } = require('@rails/actioncable');
const actionCable = createConsumer(`wss://${window.location.hostname}/cable`);

interface EventsState {
  toasts: IToastProps[];
  subscription: any;
}

export class Events extends PureComponent<{}, EventsState> {
  private toaster: React.RefObject<Toaster>;

  constructor(props: {}) {
    super(props);

    this.state = { subscription: undefined, toasts: [] };
    this.toaster = React.createRef<Toaster>();
  }

  componentDidMount (): void {
    this.subscribeToEvents();
  }

  componentWillUnmount (): void {
    actionCable.subscriptions.remove(this.state.subscription);
  }

  render (): React.ReactNode {
    return (
      <Toaster position={Position.TOP} usePortal={false} ref={this.toaster}>
        {this.state.toasts.map((toast, i) => <Toast key={`toast-${i}`} {...toast}/>)}
      </Toaster>
    );
  }

  private refreshView = () => {
    visit(window.location.href);
  }

  // How the UI processes events:
  // action: determines color of the toast and icon used (start, done, error).
  // message: contents of the toast message.
  // link: displays a 'View' button that when clicked will go to that link,
  //       this is generated by rails using the url helpers.
  // refresh: will cause the page to refresh once toast is dismissed.
  // resource_id: determins if the toast should only be fired when viewing that resource.
  private subscribeToEvents = () => {
    // tslint:disable: no-this-assignment
    const toaster = this.toaster.current;
    const { refreshView } = this;
    // tslint:enable: no-this-assignment

    if (toaster === null) {
      return;
    }

    const sub = actionCable.subscriptions.create('ImsEventsChannel', {
      connected(): void {
        console.log('ImsEventsChannel: connected, listening for events.');
      },
      disconnected(): void {
        console.log('ImsEventsChannel: disconnected.');
      },
      received(data: any): void {

        console.log(`IMSEventChannel: event received, ${JSON.stringify(data)}`);

        const { action, message, link, refresh, resource_id } = data.content;
        const actionType: string = action.split('_').slice(-1)[0];
        const actionResource: string = action.replace(`_${actionType}`, '');

        let icon: IconName = IconNames.INFO_SIGN;
        let intent: Intent = Intent.NONE;
        let timeout: number = 10000;

        if (!resource_id || (resource_id && window.location.href.includes(resource_id))) {
          if (actionType === 'done') {
            icon = IconNames.TICK_CIRCLE;
            intent = Intent.SUCCESS;
            timeout = 10000;
          } else if (actionType === 'error') {
            icon = IconNames.ERROR;
            intent = Intent.DANGER;
            timeout = 60000;
          }

          toaster.show({
            action: link ? {
              text: actionResource === 'instance_info' ? 'Refresh' : 'View',
              href: link
            } : undefined,
            message,
            intent,
            icon,
            onDismiss: refresh ? refreshView : undefined,
            timeout
          });
        }
      }
    });

    this.setState({ subscription: sub });
  }
}
