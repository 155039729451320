import * as React from 'react';
import ReactTable, { CellInfo, Column } from 'react-table';
import {
  AnchorButton,
  ButtonGroup,
  Card,
  H5,
  Text
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { getTableConfigs, TABLE_CONFIGS } from '../helpers';
import { RecentServer } from '../models';

export interface RecentServersProps {
  servers: RecentServer[];
}

export class RecentServers extends React.PureComponent<RecentServersProps> {
  render(): React.ReactNode {
    return (
      <Card className='recent-servers'>
        {this.renderHeader()}
        {this.renderTable()}
      </Card>
    );
  }

  private renderActionsCell = (row: CellInfo): React.ReactNode => {
    const data = row.original as RecentServer;

    return (
      <div className='server-actions-container'>
        <ButtonGroup>
          <AnchorButton
            href={`/servers/${data.id}`}
            icon={IconNames.EYE_OPEN}
            title='View Server' />
        </ButtonGroup>
      </div>
    );
  }

  private renderHeader = (): React.ReactNode => {
    const { servers } = this.props;
    const length = servers.length;

    return (
      <div className='card-header'>
        <div className='card-header-title'>
          <H5>Recently Created Servers <span>(last 30 days)</span></H5>
          <Text>{`Total: ${length} server${length > 1 ? 's' : ''}`}</Text>
        </div>
      </div>
    );
  }

  private renderTable = (): React.ReactNode => {
    const { servers } = this.props;

    return (
      <ReactTable
        data={servers}
        columns={this.getColumns()}
        loading={false}
        showPagination={false}
        pageSize={servers.length}
        getTableProps={getTableConfigs(TABLE_CONFIGS, 'table')}
        getTheadProps={getTableConfigs(TABLE_CONFIGS, 'thead')}
        getTheadThProps={getTableConfigs(TABLE_CONFIGS, 'theadTh')}
        getTrGroupProps={getTableConfigs(TABLE_CONFIGS, 'trGroup')}
        getTheadTrProps={getTableConfigs(TABLE_CONFIGS, 'trGroup')}
        getTdProps={getTableConfigs(TABLE_CONFIGS, 'td')}
      />
    );
  }

  private getColumns = (): Column<RecentServer>[] => {
    return [
      {
        Header: 'Customer',
        accessor: 'customer_name',
        maxWidth: 225
      },
      {
        Header: 'Server ID',
        accessor: 'id',
        maxWidth: 280
      },
      {
        Cell: (row: CellInfo) => {
          return row.original.server_type.toUpperCase();
        },
        Header: 'Type',
        maxWidth: 80
      },
      {
        Header: 'Creation Date',
        accessor: 'created_at',
        maxWidth: 200
      },
      {
        Cell: this.renderActionsCell,
        sortable: false,
        Header: 'Actions',
        headerClassName: 'no-arrow',
        maxWidth: 60
      }
    ];
  }
}
