import { EntityTimestamps } from './EntityTimestamps';

export interface License extends EntityTimestamps {
  id: string;
  license_type: LicenseType;
  hardware_id: string;
  expires_at: string;
  server_id: string;
  jwt?: string;
  network_agents?: number;
  remote_worker_agents?: number;
  agent_licenses: {
    network_agents: LicenseLimits;
    remote_worker_agents: LicenseLimits;
    [key: string]: any;
  };
  created_at: string;
  updated_at: string;
  deactivated_at: string;
}

export enum LicenseType {
  ENTERPRISE = 'enterprise',
  TRIAL = 'trial',
  LEGACY = 'legacy'
}

export interface LicenseLimits {
  agent_limit: number;
  scheduled_test_limit: number;
  target_test_limit: number;
}

export interface ExpiringLicense {
  customer_id: string;
  customer_name: string;
  server_id: string;
  license_type: string;
  expired_at: string;
}
