import { FieldProps } from 'formik';
import * as React from 'react';
import { FormGroup, Intent, TextArea } from '@blueprintjs/core';

export const renderTextAreaField = (
  label?: string,
  helpText?: string,
  labelAbove?: boolean,
  disabled?: boolean
) => (props: FieldProps): React.ReactNode => {
  const { field, form } = props;
  const { errors, touched } = form;
  const error = touched[field.name] && errors[field.name];

  return (
    <FormGroup
      label={label ? `${label}:` : ''}
      inline={!labelAbove}
      intent={Intent.NONE}
      className={`${labelAbove ? 'margin-right' : 'form-group'} ${error ? 'error' : ''}`}
    >
      <TextArea
        {...field}
        intent={Intent.NONE}
        className='input'
        disabled={disabled}
        growVertically={true}
      />
      {!!helpText && <div className='input-helper'>{helpText}</div>}
      {!!error && <div className='input-error'>{errors[field.name]}</div>}
    </FormGroup>
  );
};
