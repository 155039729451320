import * as React from 'react';
import ReactTable, { CellInfo, Column } from 'react-table';
import { Alert, AnchorButton, Button, ButtonGroup, Card, H5, Intent, IToastProps, Text } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { axiosClient, getTableConfigs, TABLE_CONFIGS } from '../helpers';

import { Agent, AgentEvent, EventTypes } from '../models';
import { Toast } from '.';

export interface AgentRMAEventsProps {
  events: AgentEvent[];
  rmaCategories: { [key: string]: string };
  agent: Agent;
}

export interface AgentRMAEventsState {
  events: AgentEvent[];
  eventToDelete: AgentEvent | undefined;
  search: string;
  showDeleteConfirmation: boolean;
  toastMessages: IToastProps[];
}

export class AgentRMAEvents extends React.PureComponent<AgentRMAEventsProps, AgentRMAEventsState> {
  constructor(props: AgentRMAEventsProps) {
    super(props);

    this.state = {
      events: props.events,
      eventToDelete: undefined,
      search: '',
      showDeleteConfirmation: false,
      toastMessages: []
    };
  }

  render(): React.ReactNode {
    return (
      <Card className='server-agents'>
        {this.renderHeader()}
        {this.renderFilters()}
        {this.renderTable()}
        {this.renderDeleteConfirmation()}
        <Toast messages={this.state.toastMessages}/>
      </Card>
    );
  }

  private renderHeader = (): React.ReactNode => {
    const { agent } = this.props;
    const { events } = this.state;

    return (
      <div className='card-header'>
        <div className='card-header-title'>
          <H5>RMA Activity</H5>
          <Text>{`Total: ${events.length} events`}</Text>
        </div>
        <AnchorButton
          href={`/agents/${agent.id}/events/new`}
          text='Add Event'
          icon='plus'
          intent={Intent.PRIMARY}
        />
      </div>
    );
  }

  private renderFilters = (): React.ReactNode => {
    const { search } = this.state;

    return (
      <div className='filters-container'>
        <input
          className='bp3-input margin-right search-container'
          type='search'
          placeholder='Search'
          value={search}
          onChange={this.onSearchChange}
        />
      </div>
    );
  }

  private renderTable = (): React.ReactNode => {
    return (
      <ReactTable
        data={this.getFilteredData()}
        columns={this.getColumns()}
        loading={false}
        getTableProps={getTableConfigs(TABLE_CONFIGS, 'table')}
        getTheadProps={getTableConfigs(TABLE_CONFIGS, 'thead')}
        getTheadThProps={getTableConfigs(TABLE_CONFIGS, 'theadTh')}
        getTrGroupProps={getTableConfigs(TABLE_CONFIGS, 'trGroup')}
        getTheadTrProps={getTableConfigs(TABLE_CONFIGS, 'trGroup')}
        getTdProps={getTableConfigs(TABLE_CONFIGS, 'td')}
      />
    );
  }

  private renderActionsCell = (row: CellInfo): React.ReactNode => {
    const data = row.original as AgentEvent;
    const { agent } = this.props;

    return (
      <div className='customer-actions-container'>
        <ButtonGroup>
          <AnchorButton
            href={`/agents/${agent.id}/events/${data.id}/edit`}
            icon={IconNames.EDIT}
            title='Update RMA Event' />
          <Button
            icon={IconNames.TRASH}
            title='Delete RMA Event'
            onClick={this.onDeleteClick(data)}
          />
        </ButtonGroup>
      </div>
    );
  }

  private renderDeleteConfirmation = (): React.ReactNode => {
    return (
      <Alert
        isOpen={this.state.showDeleteConfirmation}
        portalContainer={document.body}
        canEscapeKeyCancel={true}
        canOutsideClickCancel={true}
        cancelButtonText='Cancel'
        confirmButtonText='Delete Event'
        icon={IconNames.TRASH}
        intent={Intent.DANGER}
        onCancel={this.onDeleteCancel}
        onConfirm={this.onDeleteConfirm}
      >
        <Text>
          Are you sure you want to delete this event?
        </Text>
      </Alert>
    );
  }

  private getFilteredData = () => {
    const { search, events } = this.state;
    const { rmaCategories } = this.props;

    let eventsFiltered = [...events];

    if (search.length > 0) {
      // tslint:disable-next-line: ter-max-len
      eventsFiltered = eventsFiltered.filter(e => {
        return e.description.toLowerCase().includes(search.toLowerCase()) ||
          rmaCategories[e.rma_category].toLowerCase().includes(search.toLowerCase());
      });
    }

    return eventsFiltered;
  }

  private getColumns = (): Column[] => {
    const { rmaCategories } = this.props;

    return [
      {
        Header: 'Timestamp',
        accessor: 'occurred_at',
        maxWidth: 270
      },
      {
        id: 'rma_category',
        Header: 'Category',
        accessor: d => rmaCategories[d.rma_category],
        maxWidth: 180
      },
      {
        Header: 'Description',
        accessor: 'description'
      },
      {
        Cell: this.renderActionsCell,
        sortable: false,
        Header: 'Actions',
        headerClassName: 'no-arrow'
      }
    ];
  }

  private onDeleteClick = (event: AgentEvent) => async () => {
    this.setState({ showDeleteConfirmation: true, eventToDelete: event });
  }

  private onDeleteCancel = () => {
    this.setState({ showDeleteConfirmation: false, eventToDelete: undefined });
  }

  private onDeleteConfirm = async () => {
    const { agent } = this.props;
    const { eventToDelete } = this.state;

    try {
      const { data } = await axiosClient.delete(`/agents/${agent.id}/events/${eventToDelete?.id}.json`);
      const newEvents = data.filter((event: AgentEvent) => event.event_type === EventTypes.RMA);
      this.setState({ showDeleteConfirmation: false, eventToDelete: undefined, events: newEvents });
    } catch (error) {
      const message: IToastProps = {
        icon: IconNames.ERROR,
        intent: Intent.DANGER,
        message: `Something went wrong. Could not delete RMA event.`,
        timeout: 6000
      };

      this.setState({ showDeleteConfirmation: false, toastMessages: [message], eventToDelete: undefined });
    }
  }

  private onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ search: event.currentTarget.value });
  }
}
