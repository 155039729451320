import * as React from 'react';
import { AxiosError } from 'axios';
import { Field, FieldProps, Form, Formik } from 'formik';
import { Alert, AnchorButton, Button, Callout, FormGroup, H5, Intent, IToastProps, Text } from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import { IconNames } from '@blueprintjs/icons';
import * as yup from 'yup';
import { visit } from 'turbolinks';

import { axiosClient } from '../helpers';
import { renderSelectField, renderTextAreaField, renderTextField } from './form';

import { Agent, AgentEvent, RMACategories } from '../models';
import { Toast } from '.';
import { SelectOption } from './Select';

const validationSchema = yup.object().shape({
  description: yup.string()
    .required('Description is required')
    .max(255, 'Description must be less than 256 characters.'),
  rma_category: yup.string()
    .required('RMA Cateogry is required'),
  occurred_at: yup.string()
});

const RMAOptions: SelectOption[] = [
  { label: 'Disk Corruption', value: RMACategories.DISK_CORRUPTION },
  { label: 'POE', value: RMACategories.POE },
  { label: 'EOL', value: RMACategories.EOL },
  { label: 'Software Releated', value: RMACategories.SOFTWARE_RELATED },
  { label: 'User Related', value: RMACategories.USER_RELATED },
  { label: 'Other', value: RMACategories.OTHER }
];

type CreateUpdateAgentEventForm = Omit<AgentEvent,
  'id' | 'event_type' | 'agent_id' | 'created_at' | 'updated_at'>;

export interface CreateUpdateAgentEventProps {
  agent: Agent;
  event?: AgentEvent;
  rma_categories: { [key: string]: string };
}

export interface CreateUpdateAgentEventState {
  toastMessages: IToastProps[];
  showAgentSwapConfirmation: boolean;
  confirmedAgentSwap: boolean;
}

export class CreateUpdateAgentEvent extends React.PureComponent<
  CreateUpdateAgentEventProps,
  CreateUpdateAgentEventState
> {
  private formRef: React.RefObject<Formik<CreateUpdateAgentEventForm>>;

  constructor(props: CreateUpdateAgentEventProps) {
    super(props);

    this.state = {
      toastMessages: [],
      showAgentSwapConfirmation: false,
      confirmedAgentSwap: false
    };

    this.formRef = React.createRef<Formik<CreateUpdateAgentEventForm>>();
  }

  render(): React.ReactNode {
    const { event } = this.props;

    const initialValues: CreateUpdateAgentEventForm = {
      rma_category: event ? event.rma_category : Object.keys(this.props.rma_categories)[0],
      description: event?.description ? event.description : '',
      occurred_at: event ? event.occurred_at : new Date().toISOString(),
      replacement_agent_id: ''
    };

    return (
      <div className='flex'>
        <Formik
          onSubmit={this.onSubmit}
          render={this.renderForm}
          initialValues={initialValues}
          validationSchema={validationSchema}
          ref={this.formRef}
        />
        <Toast messages={this.state.toastMessages}/>
        {this.renderAgentSwapConfirmation()}
      </div>
    );
  }

  private renderForm = (): React.ReactNode => {
    return (
      <Form className='create-agent-event'>
        {this.renderHeader()}
        <div className='create-agent-event-fields-container'>
          <Field
            name={'rma_category'}
            render={renderSelectField(
              RMAOptions,
              'RMA Category',
              undefined,
              undefined,
              undefined,
              undefined,
              this.onRMACategoryChange
            )}
          />
          <Field
            name='description'
            render={renderTextAreaField(
              'Description',
              undefined,
              undefined,
              undefined
            )}
          />
          <Field
            name='occurred_at'
            render={this.renderDatePicker}
          />

          <Field
            name='replacement_agent_id'
            render={renderTextField(
              'Replacement Agent ID',
              `If you would like to replace this agent with a replacement agent,
                enter the ID of the replacement agent here`
            )}
          />
        </div>
      </Form>
    );
  }

  private renderHeader = (): React.ReactNode => {
    const { event, agent } = this.props;

    return (
      <div className='create-header editable-header'>
        <AnchorButton
          href={`/agents${agent ? `/${agent.id}` : '' }`}
          text='Cancel'
          intent={Intent.PRIMARY}
          minimal
        />
        <H5>{event ? 'Update RMA Event' : 'Create RMA Event'}</H5>
        <Button
          text='Save'
          intent={Intent.PRIMARY}
          type='submit'
        />
      </div>
    );
  }

  private onSubmit = (values: CreateUpdateAgentEventForm) => {
    const { event } = this.props;

    if (!event && !this.state.confirmedAgentSwap && values.replacement_agent_id !== '') {
      this.setState({ showAgentSwapConfirmation: true });
      return false;
    } else {
      this.onEventCreate(values).then(() => { return true; }).catch(() => { return false; });
    }
  }

  private onEventCreate = async (values: CreateUpdateAgentEventForm) => {
    const { agent, event } = this.props;

    try {
      if (!!event) {
        await axiosClient.put(`/agents/${agent.id}/events/${event.id}.json`, { agent_event: values });
        visit(`/agents/${agent.id}?tab=rma`);
      } else {
        await axiosClient.post(`/agents/${agent.id}/events.json`, { agent_event: { ...values, event_type: 'rma' }});
        visit(`/agents/${agent.id}?tab=rma`);
      }

    } catch (error) {
      const e = error as AxiosError;

      const message: IToastProps = {
        icon: IconNames.ERROR,
        intent: Intent.DANGER,
        message: `Something went wrong. Could not
          ${!!this.props.event ? 'update' : 'create'} event. ${e.response?.data.message}. `,
        timeout: 6000
      };

      this.setState({ toastMessages: [message] });
    }
  }

  private onAgentSwapConfirmation = () => {
    this.setState({ showAgentSwapConfirmation: false, confirmedAgentSwap: true });
    this.formRef.current?.submitForm();
  }

  private onAgentSwapCancel = () => {
    this.setState({ showAgentSwapConfirmation: false });
  }

  private onRMACategoryChange = async (rmaCategory: string) => {
    const bag = this.formRef.current?.getFormikBag();

    bag?.setFieldValue('rma_category', rmaCategory);
  }

  private renderAgentSwapConfirmation = (): React.ReactNode => {
    const bag = this.formRef.current?.getFormikBag();
    const { agent } = this.props;

    return (
      <Alert
        className='password-confirmation'
        isOpen={this.state.showAgentSwapConfirmation}
        portalContainer={document.body}
        canEscapeKeyCancel={false}
        canOutsideClickCancel={false}
        confirmButtonText='Yes, swap them'
        cancelButtonText='Cancel'
        icon={IconNames.WARNING_SIGN}
        intent={Intent.WARNING}
        onConfirm={this.onAgentSwapConfirmation}
        onCancel={this.onAgentSwapCancel}
      >
        <Text tagName='p'>
          <b>Are you sure you want to swap this agent?</b>
        </Text>
        <ul>
          <li>This Agent: {agent.id}</li>
          <li>Replacement Agent: {bag?.values.replacement_agent_id}</li>
        </ul>
        <Text tagName='p'>
          By entering a replacement agent ID you have indicated that you would like to swap the
          replacement agent with this agent. The replacement agent will assume this agent's ID
          and associated server. This agent will be assigned a different ID and be disassocated
          with the currently assigned server.
        </Text>
      </Alert>
    );
  }

  private renderDatePicker = (props: FieldProps): React.ReactNode => {
    const { field } = props;
    const date = new Date(field.value);

    return (
      <FormGroup label='Occurred Date:' inline={true} className={`form-group '`}>
        <DateInput
          value={date}
          placeholder='M/D/YYYY'
          formatDate={this.formatDate}
          parseDate={this.parseDate}
          onChange={this.onDateChange}
          popoverProps={{
            usePortal: true,
            portalContainer: document.body,
            modifiers: { preventOverflow: { enabled: false }, hide: { enabled: false }}
          }}
        />
      </FormGroup>
    );
  }

  private formatDate = (date: Date) => {
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }

  private parseDate = (str: string) => {
    const date: Date = new Date(str);

    return (date.toString() === 'Invalid Date' ? false : date);
  }

  private onDateChange = (selectedDate: Date, isUserChange: boolean) => {
    const bag = this.formRef.current?.getFormikBag();
    bag?.setFieldValue('occurred_at', selectedDate.toISOString());
  }
}
