export interface TableConfigs {
  [key: string]: {
    className: string;
  };
}

export const getTableConfigs = (TABLE_CONFIGS: TableConfigs, key: string) => {
  return () => TABLE_CONFIGS[key];
};

export const TABLE_CONFIGS: TableConfigs = {
  table: {
    className: 'r-table users'
  },
  thead: {
    className: 'thead'
  },
  theadTh: {
    className: 'th'
  },
  trGroup: {
    className: 'tr-group'
  },
  td: {
    className: 'td'
  }
};
