import * as React from 'react';
import ReactTable, { CellInfo, Column } from 'react-table';
import { AnchorButton, Button, ButtonGroup, Card, H5, Intent, Text } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { getTableConfigs, TABLE_CONFIGS } from '../helpers';

import { Customer, Server, ServerType } from '../models';

export interface ServersProps {
  servers: Server[];
  customer: Customer;
}

export class Servers extends React.PureComponent<ServersProps> {
  render(): React.ReactNode {
    return (
      <Card className='servers'>
        {this.renderHeader()}
        {this.renderTable()}
      </Card>
    );
  }

  private renderHeader = (): React.ReactNode => {
    const { customer, servers } = this.props;

    return (
      <div className='card-header margin-bottom'>
        <div className='card-header-title'>
          <H5>Servers</H5>
          <Text>{`Total: ${servers.length} servers`}</Text>
        </div>
        {!customer.deactivated_at && <AnchorButton
          text='New Server'
          icon='plus'
          href={`/customers/${customer.id}/servers/new`}
          intent={Intent.PRIMARY}
          className='invite-button'
        />}
      </div>
    );
  }

  private renderTable = (): React.ReactNode => {
    const { servers } = this.props;

    if (servers.length < 1) {
      return null;
    }

    return (
      <ReactTable
        data={servers}
        columns={this.getColumns()}
        loading={false}
        getTableProps={getTableConfigs(TABLE_CONFIGS, 'table')}
        getTheadProps={getTableConfigs(TABLE_CONFIGS, 'thead')}
        getTheadThProps={getTableConfigs(TABLE_CONFIGS, 'theadTh')}
        getTrGroupProps={getTableConfigs(TABLE_CONFIGS, 'trGroup')}
        getTheadTrProps={getTableConfigs(TABLE_CONFIGS, 'trGroup')}
        getTdProps={getTableConfigs(TABLE_CONFIGS, 'td')}
      />
    );
  }

  private renderActionsCell = (row: CellInfo): React.ReactNode => {
    const { customer } = this.props;
    const data = row.original as Server;

    return (
      <div className='customer-actions-container'>
        <ButtonGroup>
          <AnchorButton
            href={`/servers/${data.id}`}
            icon={IconNames.EYE_OPEN}
            title='View Server' />
          <AnchorButton
            disabled={data.deactivated_at !== null}
            href={data.deactivated_at !== null ? '#' : `/customers/${customer.id}/servers/${data.id}/edit`}
            icon={IconNames.EDIT}
            title='Edit Server' />
          <AnchorButton
            disabled={data.deactivated_at !== null}
            href={`/servers/${data.id}/licenses/new`}
            icon={IconNames.CUBE_ADD}
            title='New License' />
        </ButtonGroup>
      </div>
    );
  }

  private getColumns = (): Column<Server>[] => {
    return [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Server FQDN',
        accessor: 'fqdn'
      },
      {
        id: 'server_type',
        Header: 'Type',
        accessor: d => d.server_type === ServerType.AWS ? 'AWS' : 'OVA',
        maxWidth: 80
      },
      {
        Header: 'Secret key',
        accessor: 'secret_key',
        minWidth: 120
      },
      {
        Cell: this.renderActionsCell,
        sortable: false,
        Header: 'Actions',
        headerClassName: 'no-arrow'
      }
    ];
  }
}
