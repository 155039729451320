// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import WebpackerReact from 'webpacker-react';
import { Agent, Agents, CreateUpdateCustomer, CreateUpdateLicense, CreateUpdateServer, CreateUpdateAgentEvent, Customer, Customers, NavigationBar, Server, Home, Dashboard, License } from './components';

// tslint:disable-next-line: no-submodule-imports
import '@blueprintjs/core/lib/css/blueprint';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import 'react-table/react-table.css';

WebpackerReact.setup({
  Agent,
  Agents,
  CreateUpdateCustomer,
  CreateUpdateLicense,
  CreateUpdateServer,
  CreateUpdateAgentEvent,
  Customer,
  Customers,
  NavigationBar,
  Server,
  Home,
  Dashboard,
  License
});
