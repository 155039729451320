import { FieldProps } from 'formik';
import * as React from 'react';
import { FormGroup, Intent, NumericInput } from '@blueprintjs/core';

export const renderNumericField = (
  label?: string,
  helpText?: string,
  min?: number,
  max?: number,
  labelAbove?: boolean,
  disabled?: boolean
) => (props: FieldProps): React.ReactNode => {
  const { field, form } = props;
  const { setFieldValue, setFieldTouched, errors, touched } = form;
  const setFieldValueFn = (valueAsNumber: number, valueAsString: string) => {
    const value = isNaN(valueAsNumber) ? 0 : valueAsString;

    setFieldValue(field.name, value);
    setFieldTouched(field.name);
  };
  const toggleTouched = () => setFieldTouched(field.name);
  const error = touched[field.name] && errors[field.name];

  return (
    <FormGroup
      label={label ? `${label}:` : ''}
      inline={!labelAbove}
      intent={Intent.NONE}
      className={`${labelAbove ? 'margin-right' : 'form-group'} ${error ? 'error' : ''}`}
    >
      <NumericInput
        {...field}
        min={min}
        max={max}
        className='input'
        fill={true}
        disabled={disabled}
        onValueChange={setFieldValueFn}
        onBlur={toggleTouched}
      />
      {!!helpText && <div className='input-helper'>{helpText}</div>}
      {!!error && <div className='input-error'>{error}</div>}
    </FormGroup>
  );
};
