import React, { PureComponent } from 'react';
import { Alignment, Button, Navbar, NavbarDivider , NavbarGroup, NavbarHeading, Text } from '@blueprintjs/core';
import { RoleType, User } from '../models';

import SVG from 'react-inlinesvg';

import { axiosClient } from '../helpers';

import { Events } from '../components';

// tslint:disable-next-line: ter-max-len
const logo = '<svg width="40" height="40" viewBox="0 0 2013 1842" version="1.1" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;fill:white;"><g><g><path d="M1791.67,920.833c0,434.925 -352.575,787.5 -787.5,787.5c-434.921,0 -787.5,-352.575 -787.5,-787.5c0,-434.929 352.579,-787.5 787.5,-787.5c434.925,0 787.5,352.571 787.5,787.5Z" style="fill:none;fill-rule:nonzero;"/><path d="M1418.23,723.571l-298.016,0l0,111.021l298.016,0c26.905,0 47.513,-5.425 47.513,-32.325l0,-42.079c0,-26.9 -20.608,-36.617 -47.513,-36.617Z" style="fill-rule:nonzero;"/><path d="M1418.23,984.767l-298.016,0l0,130.891l298.016,0c26.905,0 47.513,-13.812 47.513,-40.708l0,-57.65c0,-26.908 -20.608,-32.533 -47.513,-32.533Z" style="fill-rule:nonzero;"/><path d="M1480.73,1255.95l-360.508,0l0,0.525l-157.375,0l-0.538,-0.525l-1.408,0l0,-1.354l-398.95,-383.375l0,385.254l-190.05,0l0,-672.629l157.379,0l400.892,385.233l0,-385.233l30.729,0l0,-0.517l519.829,0c71.113,0 142.221,70.146 142.221,142.209l0,37.47c0,73.034 -56.688,135.496 -127.804,145.096c69.179,6.73 132.612,72.075 132.612,147.984l0,57.645c-0.733,121.705 51.413,187.15 51.413,187.15c0,0 -76.688,-44.408 -198.442,-44.933Zm-476.558,-1160.12c-455.634,0 -825,369.367 -825,825c0,455.638 369.366,825 825,825c455.633,0 825,-369.362 825,-825c0,-455.633 -369.367,-825 -825,-825Z" style="fill-rule:nonzero;"/></g></g></svg>';

interface NavigationBarProps {
  currentUser?: User;
  imsVersion: string;
}

export class NavigationBar extends PureComponent<NavigationBarProps> {
  render (): React.ReactNode {
    const { currentUser, imsVersion } = this.props;
    const isLoggedIn = !!currentUser;
    const hasAdminAccess = !!currentUser && currentUser.role === RoleType.ADMIN;

    return (
      <div>
        <Navbar className='bp3-dark'>
          <NavbarGroup align={Alignment.LEFT}>
            <SVG src={logo} className='margin-right'/>
            <a href='/dashboard' className='bp3-button bp3-minimal button-link'>
              <NavbarHeading>NetBeez IMS</NavbarHeading>
            </a>
            <NavbarDivider />
            <span>{imsVersion}</span>
          </NavbarGroup>
          {isLoggedIn
            ? (
              <NavbarGroup align={Alignment.RIGHT}>
                {hasAdminAccess && (
                  <>
                    <a href='/customers' className='bp3-button bp3-minimal button-link'>Customers</a>
                    <a href='/agents' className='bp3-button bp3-minimal button-link'>Agents</a>
                    <NavbarDivider />
                  </>
                )}
                <Button minimal text='Sign out' onClick={this.onSignOutClick}/>
                <NavbarDivider />
                <Text>{currentUser?.name}</Text>
              </NavbarGroup>
            )
            : (
              <NavbarGroup align={Alignment.RIGHT}>
                <a href='/auth/google' className='bp3-button bp3-minimal button-link'>Sign In</a>
              </NavbarGroup>
            )
          }
        </Navbar>
        {isLoggedIn && hasAdminAccess && (
          <Events />
        )}
      </div>
    );
  }

  private onSignOutClick = async () => {
    await axiosClient.delete('/sessions');

    window.location.reload(false);
  }
}
