import * as React from 'react';

export interface SelectOption extends React.SelectHTMLAttributes<string> {
  label: string;
}

export interface SelectProps {
  options: SelectOption[];
  initialSelectedValue?: string | number;
  className?: string;
  value?: string | number;
  disabled?: boolean;

  onChange?: (newSelectState: string) => void;
  onBlur?: () => void;
}

interface SelectState {
  selectedValue?: string | number;
}

export class Select extends React.PureComponent<SelectProps, SelectState> {

  constructor(props: SelectProps) {
    super(props);

    this.state = { selectedValue: props.initialSelectedValue };
  }

  render(): React.ReactNode {
    const { className, onBlur, value, disabled } = this.props;
    const currentValue = value ? value : this.state.selectedValue;

    return (
      <select
        className={`select ${className || ''}`}
        onChange={this.onOptionSelected}
        onBlur={onBlur}
        value={currentValue}
        disabled={disabled}
        data-testid='select-component'
      >
        {this.renderOptions()}
      </select>
    );
  }

  private renderOptions = () => {
    return this.props.options.map(option => {
      return (
        <option
          key={option.value?.toString()}
          value={option.value}
          disabled={option.disabled}
        >
          {option.label}
        </option>
      );
    });
  }

  private onOptionSelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { onChange } = this.props;
    const newValue = event.target.value;

    this.setState({ selectedValue: newValue });

    if (!!onChange) {
      onChange(newValue);
    }
  }

}
