import * as React from 'react';
import {
  Alert,
  AnchorButton,
  Button,
  Callout,
  Card,
  H2,
  H5,
  Intent,
  IToastProps,
  Tab,
  TabId,
  Tabs,
  Text,
  TextArea
} from '@blueprintjs/core';
import { axiosClient } from '../helpers';

import { ServerAgents } from './ServerAgents';
import { Licenses } from './Licenses';
import { Downloads } from './Downloads';
import { Toast } from './Toast';
import { Agent, Customer, License, Server as ServerModel, ServerDownload, ServerType } from '../models';
import { IconNames } from '@blueprintjs/icons';
import { visit } from 'turbolinks';

export interface ServerProps {
  server: ServerModel;
  customer: Customer;
  agents: Agent[];
  licenses: License[];
  downloads: ServerDownload[];
  install_script?: string;
  events: any;
}

export interface ServerState {
  selectedTab: TabId;
  showDeactivateConfirm: boolean;
  showDeleteConfirmation: boolean;
  showTerminateConfirmation: boolean;
  disableTerminateButton: boolean;
  disableRefreshButton: boolean;
  disableDeleteButton: boolean;
  disableDeactivateButton: boolean;
  toastMessages: IToastProps[];
}

export class Server extends React.PureComponent<ServerProps, ServerState> {
  state: ServerState = {
    selectedTab: 'agents',
    showDeactivateConfirm: false,
    showDeleteConfirmation: false,
    showTerminateConfirmation: false,
    disableTerminateButton: false,
    disableRefreshButton: false,
    disableDeleteButton: false,
    disableDeactivateButton: false,
    toastMessages: []
  };

  componentDidMount(): void {
    if (this.props.server.server_type === ServerType.AWS &&
        this.props.server.aws_instance_id && !this.props.server.aws_terminated_at) {
      this.setState({ ...this.state, disableDeleteButton: true });
    }

    const queryParams = window.location.search.substring(1).split('&').find(x => x.includes('tab='));
    switch (queryParams){
      case 'tab=licenses':
        this.setState({ selectedTab: 'licenses' });
        break;

      case 'tab=install_script':
        this.setState({ selectedTab: 'install_script' });
        break;
      case 'tab=downloads':
        this.setState({ selectedTab: 'downloads' });
        break;
      default:
        this.setState({ selectedTab: 'agents' });
    }
  }

  render(): React.ReactNode {
    const { agents, downloads, licenses, server } = this.props;

    return (
      <div className='server-view'>
        <div className='flex'>
          <div className='server-details-container'>
            <H2>{`Server - ${server.name}`}</H2>
            {this.renderCustomerDetails()}
            {this.renderTerminateInstanceConfirmation()}
            {this.renderDeleteConfirmation()}
            {this.renderDeactivateConfirmation()}
          </div>
          <div className='flex flex-column server-tabs-container'>
            <Tabs onChange={this.onTabChange} renderActiveTabPanelOnly={true} selectedTabId={this.state.selectedTab}>
              <Tab id='agents' title='Agents' panel={<ServerAgents agents={agents} server={server}/>}/>
              <Tab id='licenses' title='Licenses' panel={<Licenses licenses={licenses} server={server}/>}/>
              <Tab id='downloads' title='Downloads' panel={<Downloads server={server} downloads={downloads} />} />
              <Tab id='install_script' title='Install' panel={this.renderInstallScriptPanel()}/>
            </Tabs>
          </div>
        </div>
        <Toast messages={this.state.toastMessages}/>
      </div>
    );
  }

  private renderCustomerDetails = (): React.ReactNode => {
    const { customer, server } = this.props;
    const {
      disableTerminateButton,
      disableRefreshButton,
      disableDeleteButton,
      disableDeactivateButton
    } = this.state;

    return (
      <Card className='server-details'>
        { this.renderCustomerDetailInfo() }
        <div className='server-actions'>
          {!server.deactivated_at &&
            <Button
              text='Edit Server'
              icon={IconNames.EDIT}
              onClick={this.onEditClick}
              intent={Intent.PRIMARY}
             />
          }

          {server.server_type === ServerType.AWS && !server.deactivated_at && (
            <div className='aws-actions'>
              <Button
                text='Refresh Instance Info'
                icon={IconNames.REFRESH}
                disabled={disableRefreshButton}
                onClick={this.onRefreshClick}
                intent={Intent.NONE}
               />
               {server.aws_instance_id && !server.aws_terminated_at ? (
                 <Button
                   text='Terminate Instance'
                   icon={IconNames.TRASH}
                   disabled={disableTerminateButton}
                   onClick={this.onTerminateClick}
                   intent={Intent.DANGER}
                 />
               ) : (
                <Button
                  text='Deactivate Server'
                  icon={IconNames.DISABLE}
                  disabled={disableDeactivateButton}
                  onClick={this.onDeactivateClick}
                  intent={Intent.DANGER}
                 />
               )}
            </div>
          )}
          {server.server_type !== ServerType.AWS && !server.deactivated_at && (
            <Button
            text='Deactivate Server'
            icon={IconNames.DISABLE}
            disabled={disableDeactivateButton}
            onClick={this.onDeactivateClick}
            intent={Intent.DANGER}
           />
          )}

          {server.deactivated_at && (
              <Button
                text='Delete Server'
                icon={IconNames.TRASH}
                disabled={disableDeleteButton}
                onClick={this.onDeleteClick}
                intent={Intent.DANGER}
               />
            )}
        </div>
      </Card>
    );
  }

  private renderCustomerDetailInfo = (): React.ReactNode => {
    const { customer, server } = this.props;
    return (
      <div>
        <div>
          <div className='flex-row'>
            <Text className='margin-right-small details-label'>ID:</Text>
            <Text>{server.id}</Text>
          </div>
          <div className='flex-row'>
            <Text className='margin-right-small details-label'>Name:</Text>
            <Text>{server.name}</Text>
          </div>
          <div className='flex-row'>
            <Text className='margin-right-small details-label'>Description:</Text>
            <Text>{server.description}</Text>
          </div>
          <div className='flex-row'>
            <Text className='margin-right-small details-label'>Customer:</Text>
            <a href={`/customers/${customer.id}`}>{customer.name}</a>
          </div>
          <div className='flex-row server-details-section'>
            <H5>Server Information</H5>
          </div>
          <div className='flex-row'>
            <Text className='margin-right-small details-label'>FQDN:</Text>
            <Text>{server.fqdn}</Text>
          </div>
          { server.server_type === ServerType.AWS && (
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Dashboard:</Text>
              <AnchorButton
                minimal={true}
                icon={IconNames.SHARE}
                text='View Dashboard'
                href={`https://${server.fqdn}`}
                target='_blank'
              />
            </div>
          )}
          <div className='flex-row'>
            <Text className='margin-right-small details-label'>Server type:</Text>
            <Text>{server.server_type === ServerType.AWS ? 'AWS' : 'OVA'}</Text>
          </div>
          <div className='flex-row'>
            <Text className='margin-right-small details-label'>Port:</Text>
            <Text>{server.unsecure_port}</Text>
          </div>
          <div className='flex-row'>
            <Text className='margin-right-small details-label'>Secure Port:</Text>
            <Text>{server.secure_port}</Text>
          </div>
          <div className='flex-row'>
            <Text className='margin-right-small details-label'>Websocket Port:</Text>
            <Text>{server.websocket_port}</Text>
          </div>
          <div className='flex-row'>
            <Text className='margin-right-small details-label'>Secret:</Text>
            <Text>{server.secret_key}</Text>
          </div>
          <div className='flex-row'>
            <Text className='margin-right-small details-label'>Server Version:</Text>
            <Text>{server.beezkeeper_version}</Text>
          </div>
          <div className='flex-row'>
            <Text className='margin-right-small details-label'>Created At:</Text>
            <Text>{server.created_at}</Text>
          </div>
          <div className='flex-row'>
            <Text className='margin-right-small details-label'>Updated At:</Text>
            <Text>{server.updated_at}</Text>
          </div>
          <div className='flex-row'>
            <Text className='margin-right-small details-label'>Deactivated At:</Text>
            <Text>{server.deactivated_at}</Text>
          </div>
        </div>
        { server.server_type === ServerType.AWS && (
          <div>
            <div className='flex-row server-details-section'>
              <H5>AWS Information</H5>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Region:</Text>
              <Text>{server.aws_region}</Text>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Instance ID:</Text>
              <Text>{server.aws_instance_id}</Text>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Image:</Text>
              <Text>{server.aws_instance_info?.image}</Text>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Key Pair:</Text>
              <Text>{server.aws_instance_info?.key_pair}</Text>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Name:</Text>
              <Text>{server.aws_instance_info?.name}</Text>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Public IP:</Text>
              <Text>{server.aws_instance_info?.public_ip}</Text>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Security Group:</Text>
              <Text>{server.aws_instance_info?.security_group_ids}</Text>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>State:</Text>
              <Text>{server.aws_instance_status || server.aws_instance_info?.state}</Text>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Subnet:</Text>
              <Text>{server.aws_instance_info?.subnet_id}</Text>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Type:</Text>
              <Text>{server.aws_instance_info?.type}</Text>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>VPC:</Text>
              <Text>{server.aws_instance_info?.vpc_id}</Text>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Created At:</Text>
              <Text>{server.aws_created_at}</Text>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Updated At:</Text>
              <Text>{server.aws_updated_at}</Text>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Terminated At:</Text>
              <Text>{server.aws_terminated_at}</Text>
            </div>
          </div>
        )}
      </div>
    );
  }

  private renderTerminateInstanceConfirmation = (): React.ReactNode => {
    return (
      <Alert
        isOpen={this.state.showTerminateConfirmation}
        portalContainer={document.body}
        canEscapeKeyCancel={true}
        canOutsideClickCancel={true}
        cancelButtonText='Cancel'
        confirmButtonText='Terminate Instance'
        icon={IconNames.DISABLE}
        intent={Intent.DANGER}
        onCancel={this.onTerminateCancel}
        onConfirm={this.onTerminateConfirm}
      >
        <Text>
          Are you sure you want to terminate this AWS instance?
        </Text>
      </Alert>
    );
  }

  private renderDeleteConfirmation = (): React.ReactNode => {
    return (
      <Alert
        isOpen={this.state.showDeleteConfirmation}
        portalContainer={document.body}
        canEscapeKeyCancel={true}
        canOutsideClickCancel={true}
        cancelButtonText='Cancel'
        confirmButtonText='Delete Server'
        icon={IconNames.TRASH}
        intent={Intent.DANGER}
        onCancel={this.onDeleteCancel}
        onConfirm={this.onDeleteConfirm}
      >
        <Text>
          Are you sure you want to delete this server? All server information, including certificates,
          will be permanently deleted.
        </Text>
      </Alert>
    );
  }

  private renderDeactivateConfirmation = (): React.ReactNode => {
    return (
      <Alert
        isOpen={this.state.showDeactivateConfirm}
        portalContainer={document.body}
        canEscapeKeyCancel={true}
        canOutsideClickCancel={true}
        cancelButtonText='Cancel'
        confirmButtonText='Deactivate Server'
        icon={IconNames.DISABLE}
        intent={Intent.DANGER}
        onCancel={this.onDeactivateCancel}
        onConfirm={this.onDeactivateConfirm}
      >
        <Text>
          Deactivating a Server will not delete Server data but mark this server and its licenses as no longer in use.
          Do you want to deactivate this server?
        </Text>
      </Alert>
    );
  }

  private renderInstallScriptPanel = (): JSX.Element => {
    return (
      <Card className='server-agents'>
        <div className='card-header'>
          <div className='card-header-title'>
            <H5>Install</H5>
            <Text>
              Copy, paste, and execute the command below on the desired server to start a NetBeez installation.
            </Text>
          </div>
          <Button
            text='Copy Script'
            icon='duplicate'
            onClick={this.onCopyClick}
            intent={Intent.PRIMARY}
          />
        </div>
        <Callout icon={IconNames.WARNING_SIGN} intent={Intent.WARNING}>
          NetBeez cloud hosted servers automatically execute the below command at time of provisioning.
          In the event that a failure occurrs during provisioning you may execute this command manually.
        </Callout>
        <TextArea
          className='margin-medium'
          growVertically={true}
          large={true}
          intent={Intent.PRIMARY}
          value={this.props.install_script}
          disabled={true}
        />
      </Card>
    );
  }

  private onCopyClick = () => {
    const { install_script } = this.props;

    if (install_script) {
      const textField = document.createElement('textarea');
      textField.innerText = install_script;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();

      const message: IToastProps = {
        icon: IconNames.TICK_CIRCLE,
        intent: Intent.SUCCESS,
        message: 'Install script copied.',
        timeout: 3000
      };

      this.setState({ toastMessages: [message] });
    }
  }

  private onTerminateConfirm = async () => {
    try {
      await axiosClient.put(`/servers/${this.props.server.id}/terminate_instance.json`);
      this.setState({ showTerminateConfirmation: false , disableTerminateButton: true });
    } catch (error) {
      this.setState({ showTerminateConfirmation: false , disableTerminateButton: false });
    }
  }

  private onDeactivateConfirm = async () => {
    try {
      await axiosClient.put(`/servers/${this.props.server.id}/deactivate.json`);
      visit(`/customers/${this.props.customer.id}/servers/${this.props.server.id}`);
    } catch (error) {
      this.setState({ showTerminateConfirmation: false , disableTerminateButton: false });
    }
  }

  private onDeleteConfirm = async () => {
    try {
      await axiosClient.delete(`/servers/${this.props.server.id}.json`);
      visit(`/customers/${this.props.customer.id}`);
    } catch (error) {
      this.setState({ showTerminateConfirmation: false , disableTerminateButton: false });
    }
  }

  private onDeactivateClick = () => {
    this.setState({ ...this.state, showDeactivateConfirm: true, disableDeactivateButton: true });
  }

  private onDeleteClick = () => {
    this.setState({ ...this.state, showDeleteConfirmation: true, disableDeleteButton: true });
  }

  private onRefreshClick = async () => {
    this.setState({ ...this.state, disableRefreshButton: true });
    await axiosClient.put(`/servers/${this.props.server.id}/refresh_instance_info.json`);
  }

  private onTerminateClick = () => {
    this.setState({ showTerminateConfirmation: true, disableTerminateButton: true });
  }

  private onTerminateCancel = () => {
    this.setState({ showTerminateConfirmation: false, disableTerminateButton: false });
  }

  private onDeactivateCancel = () => {
    this.setState({ ...this.state, showDeactivateConfirm: false, disableDeactivateButton: false });
  }

  private onDeleteCancel = () => {
    this.setState({ ...this.state, showDeleteConfirmation: false, disableDeleteButton: false });
  }

  private onEditClick = () => {
    visit(`/customers/${this.props.customer.id}/servers/${this.props.server.id}/edit`);
  }

  private onTabChange = (newTabId: TabId) => {
    this.setState({ ...this.state, selectedTab: newTabId });
  }
}
