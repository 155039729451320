import * as React from 'react';
import ReactTable, { CellInfo, Column } from 'react-table';
import {
  AnchorButton,
  Button,
  ButtonGroup,
  Callout,
  Card,
  Classes,
  H5,
  Icon,
  Intent,
  IToastProps,
  Menu,
  MenuItem,
  Popover,
  PopoverInteractionKind,
  Text
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ItemListRenderer, ItemPredicate, ItemRenderer, Select } from '@blueprintjs/select';

import { axiosClient, getTableConfigs, TABLE_CONFIGS } from '../helpers';

import { Agent, Server } from '../models';
import { Toast } from '../components';

const SeverSelect = Select.ofType<ServerBase>();

type ServerBase = Pick<Server, 'id' | 'name'>;

export interface AgentsProps {
  agents: Agent[];
  server?: Server;
  servers: {
    [key: string]: ServerBase[];
  };
}

export interface AgentsState {
  agents: Agent[];
  search: string;
  isLinked?: boolean;
  server: ServerBase | undefined;
  servers: ServerBase[];
  toastMessages: IToastProps[];
}

export class Agents extends React.PureComponent<AgentsProps, AgentsState> {
  constructor(props: AgentsProps) {
    super(props);

    let servers: ServerBase[] = [];
    Object.values(props.servers).forEach(customerServers => servers = servers.concat(customerServers));

    this.state = {
      agents: props.agents,
      search: '',
      server: props.server || undefined,
      servers,
      isLinked: props.server ? false : undefined,
      toastMessages: []
    };
  }

  render(): React.ReactNode {
    const { server } = this.state;

    return (
      <Card className='agents'>
        {this.renderHeader()}
        <Callout intent={Intent.PRIMARY}>
          {server?.name ? (
            <Text>Clicking the Link button will link an agent to server
            <strong>{` ${server?.name}`}</strong>.
            </Text>
          ) : (
            <Text>Select a server to link an agent.</Text>
          )}
        </Callout>
        {this.renderFilters()}
        {this.renderTable()}
        <Toast messages={this.state.toastMessages}/>
      </Card>
    );
  }

  private renderHeader = (): React.ReactNode => {
    const { agents } = this.props;

    return (
      <div className='card-header margin-bottom'>
        <div className='card-header-title'>
          <H5>Agents</H5>
          <Text>{`Total: ${agents.length} agents`}</Text>
        </div>
      </div>
    );
  }

  private renderFilters = (): React.ReactNode => {
    const { search, isLinked, server: selectedServer, servers } = this.state;
    const { server } = this.props;
    const isActiveLabel = isLinked === undefined ? 'Linked & Unlinked' : isLinked ? 'Linked' : 'Unlinked';

    return (
      <div className='filters-container space-between-center'>
        <div>
          <input
            className='bp3-input margin-right search-container'
            type='search'
            placeholder='Search'
            value={search}
            onChange={this.onSearchChange}
          />
          <Popover
            interactionKind={PopoverInteractionKind.CLICK}
            position='bottom-left'
            usePortal={false}
          >
            <div className='filters-dropdown linked-dropdown'>
              <Text className='margin-right'>{isActiveLabel}</Text>
              <Icon icon='chevron-down'/>
            </div>
            {this.renderLinkedDropdown()}
          </Popover>
        </div>
        <div className='flex-row align-center'>
          <Text className='margin-right'>Select Server</Text>
          <SeverSelect
            popoverProps={{ minimal: true, usePortal: false, position: 'bottom-right' }}
            disabled={!!server}
            items={servers}
            onItemSelect={this.onSelectedServerChange}
            itemRenderer={this.renderServerOption}
            itemListRenderer={this.renderMenu}
            itemPredicate={this.filterServer}
            className='server-select'
          >
            <Button
              rightIcon='chevron-down'
              text={selectedServer ? selectedServer.name : 'Select A Server'}
              className='select-server-button'
              disabled={!!server}
            />
          </SeverSelect>
        </div>
      </div>
    );
  }

  private renderMenu: ItemListRenderer<ServerBase> = ({ items, itemsParentRef, query, renderItem }) => {
    const { servers } = this.props;

    const renderedItems = Object.keys(servers).map(customerKey => {
      const customerServers = servers[customerKey];
      const showMenuHeader = customerServers.find(s => s.name.includes(query));

      if (!showMenuHeader) {
        return null;
      }

      return [
        <li className='bp3-menu-header' key={customerKey}><h6 className='bp3-heading'>{customerKey}</h6></li>,
        customerServers.map(renderItem)
      ];
    })
    .filter(Boolean);

    return (
      <Menu ulRef={itemsParentRef}>
        {renderedItems}
      </Menu>
    );
  }

  private renderServerOption: ItemRenderer<ServerBase> = (server, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const text = `${server.name}`;

    return (
        <MenuItem
          active={modifiers.active}
          disabled={modifiers.disabled}
          key={server.id}
          onClick={handleClick}
          text={text}
        />
    );
  }

  private filterServer: ItemPredicate<ServerBase> = (query, server, _index, exactMatch) => {
    const normalizedName = server.name.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedName === normalizedQuery;
    } else {
      return normalizedName.indexOf(normalizedQuery) >= 0;
    }
  }

  private renderLinkedDropdown = (): React.ReactNode => {
    const { isLinked } = this.state;

    return (
      <div className='filters-dropdown-content'>
        <div className={`container ${Classes.POPOVER_DISMISS}`}>
          <div className='item' onClick={this.onRolesDropdownItemClick(true)}>
            Linked
            {!!isLinked && <Icon icon='tick'/>}
          </div>
          <div className='item' onClick={this.onRolesDropdownItemClick(false)}>
            Unlinked
            {isLinked === false && <Icon icon='tick'/>}
          </div>
        </div>
      </div>
    );
  }

  private renderTable = (): React.ReactNode => {
    const agents = this.getFilteredData();

    return (
      <ReactTable
        data={agents}
        columns={this.getColumns()}
        loading={false}
        getTableProps={getTableConfigs(TABLE_CONFIGS, 'table')}
        getTheadProps={getTableConfigs(TABLE_CONFIGS, 'thead')}
        getTheadThProps={getTableConfigs(TABLE_CONFIGS, 'theadTh')}
        getTrGroupProps={getTableConfigs(TABLE_CONFIGS, 'trGroup')}
        getTheadTrProps={getTableConfigs(TABLE_CONFIGS, 'trGroup')}
        getTdProps={getTableConfigs(TABLE_CONFIGS, 'td')}
      />
    );
  }

  private renderServerID = (row: CellInfo): React.ReactNode => {
    const { servers } = this.props;

    if (row.original.server_id === null) {
      return (<span/>);
    } else {
      return(
        <a
          href={`/servers/${row.original.server_id}`}
          title='View Server'
        >{row.original.server_id}</a>
      );
    }
  }

  private renderActionsCell = (row: CellInfo): React.ReactNode => {
    const data = row.original as Agent;
    const { server } = this.state;

    return (
      <div className='customer-actions-container'>
        <ButtonGroup>
          <AnchorButton
            href={`/agents/${data.id}`}
            icon={IconNames.EYE_OPEN}
            title='View Agent' />
          <AnchorButton
            // disabled={(currentDate > dateExpired) || data.deactivated_at !== null }
            href={`/agents/${data.id}/edit`}
            icon={IconNames.EDIT}
            title='Edit Agent'
          />
          {data.server_id ? (
              <Button
                icon={IconNames.UNLOCK}
                title='Unlink Agent'
                onClick={this.onLinkClick(data, false)}
              />
            ) : (
              <Button
                disabled={!server}
                icon={IconNames.LOCK}
                title='Link Agent'
                onClick={this.onLinkClick(data, true)}
              />
            )
          }
        </ButtonGroup>
      </div>
    );
  }

  private getFilteredData = (): Agent[] => {
    const { agents, search, isLinked } = this.state;

    let agentsFiltered = [...agents];

    if (search.length > 0) {
      agentsFiltered = agentsFiltered.filter(a => a.id.includes(search)
        || a.mac_address.includes(search)
      );
    }

    if (isLinked !== undefined) {
      agentsFiltered = agentsFiltered.filter(a => !!a.server_id === isLinked);
    }

    return agentsFiltered;
  }

  private onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ search: event.currentTarget.value });
  }

  private onRolesDropdownItemClick = (isLinked: boolean) => () => {
    if (this.state.isLinked === isLinked) {
      this.setState({ isLinked: undefined });
    } else {
      this.setState({ isLinked });
    }
  }

  private onSelectedServerChange = (server: ServerBase) => {
    this.setState({ server });
  }

  private onLinkClick = (agent: Agent, linkMode: boolean) => async () => {
    const { server, agents } = this.state;

    if (!server && linkMode) {
      const message: IToastProps = {
        icon: IconNames.ERROR,
        intent: Intent.DANGER,
        message: 'Server to link has to be selected.',
        timeout: 6000
      };
      this.setState({ toastMessages: [message] });
    } else {
      const { data }: { data: Agent } = await axiosClient.put(`/agents/${agent.id}.json`, {
        agent: { server_uuid: linkMode ? server?.id : null }
      });

      // Update linked agent data
      let newAgents = [...agents];
      newAgents = newAgents.map(a => {
        if (a.id !== data.id) {
          return a;
        } else {
          return data;
        }
      });

      const message: IToastProps = {
        icon: IconNames.TICK_CIRCLE,
        intent: Intent.SUCCESS,
        message: `Agent ${linkMode ? 'linked to server' : 'unlinked from server'} successfully.`,
        timeout: 3000
      };
      this.setState({ toastMessages: [message], agents: newAgents });
    }
  }

  private getColumns = (): Column<Agent>[] => {
    const { servers } = this.props;

    return [
      {
        Header: 'UUID',
        accessor: 'id',
        width: 260
      },
      {
        Header: 'MAC',
        accessor: 'mac_address',
        width: 125
      },
      {
        Header: 'Model',
        accessor: 'model',
        width: 120
      },
      {
        Header: 'Last Seen',
        accessor: 'last_seen_at',
        width: 190
      },
      {
        id: 'poe',
        Header: 'PoE',
        accessor: d => d.poe ? 'Yes' : 'No',
        width: 60
      },
      {
        id: 'customer',
        Header: 'Customer',
        accessor: d => Object.keys(servers).filter((k: any) => {
          const server = servers[k].find(s => {
            return s.id === d.server_id;
          });

          return server !== undefined ? k : '';
        })
      },
      {
        Cell: this.renderServerID,
        id: 'server_id',
        Header: 'Server'
      },
      {
        Cell: this.renderActionsCell,
        sortable: false,
        Header: 'Actions',
        headerClassName: 'no-arrow'
      }
    ];
  }
}
