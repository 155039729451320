import * as React from 'react';
import { Button, Card, H2, Intent, IToastProps, Tab, TabId, Tabs, Text, TextArea } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import QRCode from 'qrcode.react';

import { Agent as AgentModel, AgentEvent, EventTypes, Server } from '../models';
import { AgentActivityEvents, AgentRMAEvents, Toast } from '../components';


export interface AgentProps {
  agent: AgentModel;
  events: AgentEvent[];
  event_types: { [key: string]: string };
  rma_categories: { [key: string]: string };
  server?: Server;
}

export interface AgentState {
  selectedTab: TabId;
  toastMessages: IToastProps[];
}

export class Agent extends React.PureComponent<AgentProps, AgentState> {
  state: AgentState = { selectedTab: 'activity', toastMessages: [] };

  componentDidMount(): void {
    const queryParams = window.location.search.substring(1).split('&').find(x => x.includes('tab='));
    switch (queryParams){
      case 'tab=rma':
        this.setState({ selectedTab: 'rma' });
        break;
      default:
        this.setState({ selectedTab: 'activity' });
    }
  }

  render(): React.ReactNode {
    return (
      <div className='agent-view'>
        <div className='flex'>
          <div className='flex flex-column agent-details-container'>
            <H2>Agent</H2>
            {this.renderDetails()}
          </div>
          <div className='flex flex-column agent-tabs-container'>
            <Tabs onChange={this.onTabChange} renderActiveTabPanelOnly={true} selectedTabId={this.state.selectedTab}>
              <Tab
                id='activity'
                title='Agent Activity'
                panel={
                  <AgentActivityEvents
                    agent={this.props.agent}
                    events={this.props.events.filter(event => event.event_type !== EventTypes.RMA)}
                    eventTypes={this.props.event_types}
                  />}
              />
              <Tab
                id='rma'
                title='RMA Activity'
                panel={
                  <AgentRMAEvents
                    agent={this.props.agent}
                    events={this.props.events.filter(event => event.event_type === EventTypes.RMA)}
                    rmaCategories={this.props.rma_categories}
                  />}
              />
            </Tabs>
          </div>
        </div>
      </div>
    );
  }

  private renderDetails = (): React.ReactNode => {
    const { agent, server } = this.props;

    return (
      <Card className='agent-details'>
        {server && (
          <div className='flex-row'>
            <Text className='margin-right-small label'>Server:</Text>
            <a href={`/servers/${server.id}`}>{server.name}</a>
          </div>
        )}
        <div className='flex-row'>
          <Text className='margin-right-small label'>ID:</Text>
          <Text>{agent.id}</Text>
        </div>
        <div className='flex-row'>
          <Text className='margin-right-small label'>MAC:</Text>
          <Text>{agent.mac_address}</Text>
        </div>
        <div className='flex-row'>
          <Text className='margin-right-small label'>Model:</Text>
          <Text>{agent.model}</Text>
        </div>
        {agent.description && (
          <div className='flex-row'>
            <Text className='margin-right-small label'>Description:</Text>
            <Text>{agent.description}</Text>
          </div>
        )}
        <div className='flex-row'>
          <Text className='margin-right-small label'>PoE:</Text>
          <Text>{agent.poe ? 'Yes' : 'No'}</Text>
        </div>
        <div className='flex-row'>
          <Text className='margin-right-small label'>Created At:</Text>
          <Text>{agent.created_at}</Text>
        </div>
        <div className='flex-row'>
          <Text className='margin-right-small label'>Updated At:</Text>
          <Text>{agent.updated_at}</Text>
        </div>
        <div className='flex-row'>
          <Text className='margin-right-small label'>Last Seen At:</Text>
          <Text>{agent.last_seen_at}</Text>
        </div>
        {this.renderQRCode()}
        <div className='agent-actions'>
           <Button
            icon={IconNames.PRINT}
            text='Print QR Code'
            intent={Intent.PRIMARY}
            onClick={this.onPrintClick}
          />
        </div>
      </Card>
    );
  }

  private renderQRCode = (): React.ReactNode => {
    const { agent } = this.props;

    const qrValue = JSON.stringify({
      uuid: agent.id,
      mac: agent.mac_address
    }, null, 2);

    return (
      <div className='qr-container'>
        <div className='margin-bottom-large'>
          <QRCode
            renderAs='svg'
            value={qrValue}
            size={256}
            className='qr'
          />
          <div className='qr-mac'
            style={{ fontFamily: 'Helvetica Neue' }}
          >
            &copy; NetBeez, Inc.
          </div>
          <div className='qr-mac'
            style={{ fontFamily: 'Helvetica Neue' }}
          >
            {agent.mac_address}
          </div>
        </div>

        <TextArea
          growVertically={true}
          intent={Intent.PRIMARY}
          value={qrValue}
          disabled={true}
          className='textarea'
          cols={50}
        />

        <iframe id='qrcode-print'/>
        <Toast messages={this.state.toastMessages}/>
      </div>
    );
  }

  private onPrintClick = () => {
    const qrCodeContent = document.getElementsByClassName('qr-container')[0].children[0];
    const printFrame = (document.getElementById('qrcode-print') as HTMLIFrameElement)?.contentWindow;

    if (printFrame) {
      const styles = `
        @media print {
          body { margin: 0; }
          @page { size: 0.94in 0.99in; margin: 0; padding: 0 }
          .qr-mac { font-size: 12px; line-height: 12px; margin: 0; padding: 0; }
          svg { width: 100%; height: auto; margin: 0; }
        }';
      `;

      printFrame.document.open();
      printFrame.document.write(`<head><style>${styles}</style></head><body>${qrCodeContent.innerHTML}</body>`);
      printFrame.document.close();
      printFrame.focus();
      printFrame.print();
    } else {
      const message: IToastProps = {
        icon: IconNames.ERROR,
        intent: Intent.DANGER,
        message: `Something went wrong. Could not print Agent QR code.`,
        timeout: 6000
      };

      this.setState({ toastMessages: [message] });
    }
  }

  private onTabChange = (newTabId: TabId) => {
    this.setState({ ...this.state, selectedTab: newTabId });
  }
}
