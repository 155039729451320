import * as React from 'react';
import ReactTable, { CellInfo, Column } from 'react-table';
import {
  AnchorButton,
  ButtonGroup,
  Card,
  Classes,
  H5,
  Icon,
  Intent,
  Popover,
  PopoverInteractionKind,
  Text
} from '@blueprintjs/core';

import { getTableConfigs, TABLE_CONFIGS } from '../helpers';

import { Customer as CustomerModel } from '../models';
import { IconNames } from '@blueprintjs/icons';

export interface CustomersProps {
  customers: CustomerModel[];
}

export interface CustomersState {
  search: string;
  isActive?: boolean;
}

export class Customers extends React.PureComponent<CustomersProps, CustomersState> {
  state: CustomersState = {
    search: ''
  };

  render(): React.ReactNode {
    return (
      <Card className='customer'>
        {this.renderHeader()}
        {this.renderFilters()}
        {this.renderTable()}
      </Card>
    );
  }

  private renderHeader = (): React.ReactNode => {
    const { customers } = this.props;

    return (
      <div className='card-header'>
        <div className='card-header-title'>
          <H5>Customers</H5>
          <Text>{`Total: ${customers.length} customers`}</Text>
        </div>
        <AnchorButton
          text='New Customer'
          icon={IconNames.PLUS}
          href='/customers/new'
          intent={Intent.PRIMARY}
        />
      </div>
    );
  }

  private renderFilters = (): React.ReactNode => {
    const { isActive, search } = this.state;
    const isActiveLabel = isActive === undefined ? 'All Customers' : isActive ? 'Active' : 'Inactive';

    return (
      <div className='filters-container'>
        <input
          className='bp3-input margin-right search-container'
          type='search'
          placeholder='Search'
          value={search}
          onChange={this.onSearchChange}
        />
        <Popover
          interactionKind={PopoverInteractionKind.CLICK}
          position='bottom-left'
          usePortal={false}
        >
          <div className='filters-dropdown'>
            <Text className='margin-right'>{isActiveLabel}</Text>
            <Icon icon={IconNames.CHEVRON_DOWN}/>
          </div>
          {this.renderIsActiveDropdown()}
        </Popover>
      </div>
    );
  }

  private renderIsActiveDropdown = (): React.ReactNode => {
    const { isActive } = this.state;

    return (
      <div className='filters-dropdown-content'>
        <div className={`container ${Classes.POPOVER_DISMISS}`}>
          <div className='item' onClick={this.onRolesDropdownItemClick(true)}>
            Active
            {!!isActive && <Icon icon={IconNames.TICK}/>}
          </div>
          <div className='item' onClick={this.onRolesDropdownItemClick(false)}>
            Inactive
            {isActive === false && <Icon icon={IconNames.TICK}/>}
          </div>
        </div>
      </div>
    );
  }

  private renderTable = (): React.ReactNode => {
    const customers = this.getFilteredData();

    return (
      <ReactTable
        data={customers}
        columns={this.getColumns()}
        loading={false}
        getTableProps={getTableConfigs(TABLE_CONFIGS, 'table')}
        getTheadProps={getTableConfigs(TABLE_CONFIGS, 'thead')}
        getTheadThProps={getTableConfigs(TABLE_CONFIGS, 'theadTh')}
        getTrGroupProps={getTableConfigs(TABLE_CONFIGS, 'trGroup')}
        getTheadTrProps={getTableConfigs(TABLE_CONFIGS, 'trGroup')}
        getTdProps={getTableConfigs(TABLE_CONFIGS, 'td')}
      />
    );
  }

  private renderActionsCell = (row: CellInfo): React.ReactNode => {
    const data = row.original as CustomerModel;

    return (
      <div className='customer-actions-container'>
        <ButtonGroup>
          <AnchorButton href={`/customers/${data.id}`} icon={IconNames.EYE_OPEN} title='View Customer' />
          <AnchorButton href={`/customers/${data.id}/edit`} icon={IconNames.EDIT} title='Edit Customer' />
        </ButtonGroup>
      </div>
    );
  }

  private getFilteredData = () => {
    const { customers } = this.props;
    const { search, isActive } = this.state;

    let customersFiltered = [...customers];

    if (search.length > 0) {
      customersFiltered = customersFiltered.filter(c => {
        return c.name.toLowerCase().includes(search.toLowerCase()) || c.id.includes(search.toLowerCase());
      });
    }

    if (isActive !== undefined) {
      customersFiltered = customersFiltered.filter(c => !!c.deactivated_at !== isActive);
    }

    return customersFiltered;
  }

  private onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ search: event.currentTarget.value });
  }

  private onRolesDropdownItemClick = (isActive: boolean) => () => {
    if (this.state.isActive === isActive) {
      this.setState({ isActive: undefined });
    } else {
      this.setState({ isActive });
    }
  }

  private getColumns = (): Column[] => {
    return [
      {
        Header: 'ID',
        accessor: 'id',
        maxWidth: 280
      },
      {
        Header: 'Name',
        accessor: 'name',
        maxWidth: 400
      },
      {
        Cell: this.renderActionsCell,
        sortable: false,
        Header: 'Actions',
        headerClassName: 'no-arrow'
      }
    ];
  }
}
