import { EntityTimestamps } from './EntityTimestamps';

export interface Server extends EntityTimestamps {
  id: string;
  beezkeeper_channel: string;
  beezkeeper_version: string;
  created_at: string;
  customer_id: number;
  deactivated_at: string;
  fqdn: string;
  name: string;
  secret_key: string;
  secure_port: number;
  server_type: ServerType;
  unsecure_port: number;
  updated_at: string;
  websocket_port: number;
  description?: string;
  aws_instance_id?: string;
  aws_instance_status?: string;
  aws_created_at?: string;
  aws_updated_at?: string;
  aws_region?: string;
  aws_terminated_at?: string;
  aws_terminate_requested_at?: string;
  aws_instance_info?: AWSInstanceInfo;
}

export interface ServerDownload {
  name: string;
  md5: string;
}

export enum ServerType {
  AWS = 'aws',
  OVA = 'ova'
}

export interface AWSInstanceInfo {
  id: string;
  image: string;
  key_pair: string;
  name: string;
  public_ip: string;
  type: string;
  security_group_ids: string[];
  state: string;
  subnet_id: string;
  vpc_id: string;
}

export interface RecentServer {
  customer_id: string;
  customer_name: string;
  id: string;
  server_type: string;
  created_at: string;
}
