import * as React from 'react';
import { Field, Form, Formik, FormikProps } from 'formik';
import { Alert, AnchorButton, Button, Callout, Divider, H5, Intent, Text, TextArea } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { visit } from 'turbolinks';
import * as yup from 'yup';

import { axiosClient } from '../helpers';
import { renderNumericField, renderSelectField, renderTextField } from './form';

import { Customer, Server, ServerType } from '../models';
import { SelectOption } from './Select';

const ipRegex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
const hostRegex =
  /^((([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9]))$/;
const fqdnRegex = new RegExp('(' + ipRegex.source + ')|(' + hostRegex.source + ')');

const validationSchemaUpdate = yup.object().shape({
  name: yup.string()
    .required('Name is required')
    .max(255, 'First name must be less than 256 characters.'),
  fqdn: yup.string()
    .required('FQDN is required')
    .matches(fqdnRegex, 'You must provide a valid fqdn.'),
  description: yup.string()
    .max(255, 'Description must be less than 256 characters.'),
  secure_port: yup.number()
    .required('Port is required.')
    .min(1, 'Port has to greater than 0.')
    .max(65535, 'Port uas to be less than 65535.'),
  unsecure_port: yup.number()
    .required('Port is required.')
    .min(1, 'Port has to greater than 0.')
    .max(65535, 'Port uas to be less than 65535.'),
  websocket_port: yup.number()
    .required('Port is required.')
    .min(1, 'Port has to greater than 0.')
    .max(65535, 'Port uas to be less than 65535.')
});

const validationSchemaCreate = yup.object().shape({
  beezkeeper_channel: yup.string()
    .required('Channel is required.'),
  beezkeeper_version: yup.string()
    .required('Release is required.'),
  name: yup.string()
    .required('Name is required')
    .max(255, 'First name must be less than 256 characters.'),
  fqdn: yup.string()
    .required('FQDN is required')
    .matches(fqdnRegex, 'You must provide a valid fqdn.'),
  description: yup.string()
    .max(255, 'Description must be less than 256 characters.'),
  secure_port: yup.number()
    .required('Port is required.')
    .min(1, 'Port has to greater than 0.')
    .max(65535, 'Port uas to be less than 65535.'),
  unsecure_port: yup.number()
    .required('Port is required.')
    .min(1, 'Port has to greater than 0.')
    .max(65535, 'Port uas to be less than 65535.'),
  websocket_port: yup.number()
    .required('Port is required.')
    .min(1, 'Port has to greater than 0.')
    .max(65535, 'Port uas to be less than 65535.'),
  awsConfig: yup.object().when('server_type', {
    is: ServerType.AWS,
    then: yup.object().shape({
      region: yup.string()
        .required('Region is required.'),
      image_id: yup.string()
        .required('Image ID is required.'),
      security_group_ids: yup.string()
        .required('Security group is required.'),
      subnet_id: yup.string()
        .required('Subnet is required.'),
      instance_type: yup.string()
        .required('Instance type is required.'),
      volume_size: yup.string()
        .required('Volume size is required.'),
      key_name: yup.string()
      .required('Key is required.')
    })
  })
});

const SERVER_TYPE_OPTIONS: SelectOption[] = [
  { label: 'AWS', value: ServerType.AWS },
  { label: 'OVA', value: ServerType.OVA }
];

interface AWSConfig {
  region: string;
  instance_type: string;
  key_name: string;
  security_group_ids: string;
  subnet_id: string;
  volume_size: string;
  image_id: string;
}

interface AWSOptions {
  amis: string[];
  security_groups: string[];
  subnets: string[];
  instance_types: string[];
  keys: string[];
}

interface BundleOptions {
  channels: string[];
  releases: string[];
}

type CreateUpdateServerForm = Omit<Server,
  'id' |
  'aws_instance_id' |
  'aws_instance_info' |
  'aws_instance_status' |
  'aws_created_at' |
  'aws_updated_at' |
  'aws_terminated_at' |
  'aws_terminate_requested_at' |
  'customer_id' |
  'deactivated_at' |
  'created_at' |
  'updated_at' |
  'secret_key'
> & {
  awsConfig?: AWSConfig;
};

export interface CreateUpdateServerProps {
  server?: Server;
  customer: Customer;
  regions: string[];
  aws_options: AWSOptions;
  bundle_options?: BundleOptions;
}

export interface CreateUpdateServerState {
  error?: boolean;
  aws_options: AWSOptions;
  bundle_options?: BundleOptions;
  showPasswordConfirmation: boolean;
  password?: string;
  serverId?: string;
}

export class CreateUpdateServer extends React.PureComponent<CreateUpdateServerProps, CreateUpdateServerState> {
  private formRef: React.RefObject<Formik<CreateUpdateServerForm>>;

  constructor(props: CreateUpdateServerProps) {
    super(props);

    this.state = {
      error: undefined,
      aws_options: this.props.aws_options,
      bundle_options: this.props.bundle_options,
      showPasswordConfirmation: false
    };

    this.formRef = React.createRef<Formik<CreateUpdateServerForm>>();
  }

  // tslint:disable-next-line: cyclomatic-complexity
  render(): React.ReactNode {
    const { aws_options, server } = this.props;

    const initialValues: CreateUpdateServerForm = {
      beezkeeper_channel: server && server.beezkeeper_channel ? server.beezkeeper_channel : 'production',
      beezkeeper_version: server && server.beezkeeper_version ? server.beezkeeper_version :
        this.state.bundle_options?.releases[0] || '',
      name: server ? server.name : '',
      fqdn: server ? server.fqdn : '.netbeezcloud.net',
      description: server ? server.description : '',
      server_type: server ? server.server_type : ServerType.AWS,
      secure_port: server ? server.secure_port : 20018,
      unsecure_port: server ? server.unsecure_port : 20019,
      websocket_port: server ? server.websocket_port : 443,
      awsConfig: {
        region: 'us-east-1',
        instance_type: 't3.medium',
        key_name: aws_options.keys.includes('ims_key') ? 'ims_key' : aws_options.keys[0],
        security_group_ids: aws_options.security_groups[0] || '',
        subnet_id: aws_options.subnets[0] || '',
        volume_size: '100',
        image_id: aws_options.amis[0] || ''
      }
    };

    return (
      <div className='flex'>
        <Formik
          onSubmit={this.onCreateServer}
          render={this.renderForm}
          initialValues={initialValues}
          ref={this.formRef}
          validationSchema={!!server ? validationSchemaUpdate : validationSchemaCreate}
        />
        {this.renderPasswordConfirmation()}
      </div>
    );
  }

  private renderForm = (props: FormikProps<CreateUpdateServerForm>): React.ReactNode => {
    const { server } = this.props;

    return (
      <Form className='create-server'>
        {this.renderHeader()}
        {this.renderError()}
        <div className='create-server-fields-container'>
          {(props.values.server_type === ServerType.OVA && !this.editMode()) && this.renderBundleConfigForm(props)}
          {(props.values.server_type === ServerType.AWS &&
           (!this.editMode() || (this.editMode() &&
           !server?.aws_instance_id)) &&
           this.renderBundleConfigForm(props))}
          { this.renderGeneralConfigForm(props) }

          {props.values.server_type === ServerType.AWS &&
           !server?.aws_instance_id &&
           this.renderAwsConfigForm(props)}
        </div>
      </Form>
    );
  }

  private renderGeneralConfigForm = (props: FormikProps<CreateUpdateServerForm>): React.ReactNode => {
    const { server } = this.props;
    const disableAwsEdit = !!server && props.values.server_type === ServerType.AWS;
    const onNameChange = props.values.server_type === ServerType.AWS ? this.onNameChange : undefined;

    return (
      <div>
        <Text>General Config</Text>
        <Divider />
        <Field
          name={'server_type'}
          render={renderSelectField(
            SERVER_TYPE_OPTIONS,
            'Server type',
            undefined,
            undefined,
            this.editMode(),
            undefined,
            this.onServerTypeChange
          )}
        />
        <Field
          name='name'
          render={renderTextField('Name', undefined, undefined, undefined, disableAwsEdit, onNameChange)}
        />
        <Field
          name='fqdn'
          render={renderTextField('FQDN/IP', undefined, undefined, undefined, disableAwsEdit)}
        />
        <Field
          name='description'
          render={renderTextField('Description')}
        />
        <Field
          name='unsecure_port'
          render={renderNumericField('Unsecure Port')}
        />
        <Field
          name='secure_port'
          render={renderNumericField('Secure Port')}
        />
        <Field
          name='websocket_port'
          render={renderNumericField('Dashboard Port')}
        />
      </div>
    );
  }

  private renderAwsConfigForm = (props: FormikProps<CreateUpdateServerForm>): React.ReactNode => {
    const { regions } = this.props;
    const { aws_options } = this.state;
    const regionOptions = this.arrayToSelectListOptions(regions);
    const amiOptions = this.arrayToSelectListOptions(aws_options.amis);
    const securityGroupsOptions = this.arrayToSelectListOptions(aws_options.security_groups);
    const subnetsOptions = this.arrayToSelectListOptions(aws_options.subnets);
    const instanceTypesOptions = this.arrayToSelectListOptions(aws_options.instance_types);
    const keysOptions = this.arrayToSelectListOptions(aws_options.keys);

    return (
      <div>
        <Text>AWS Config</Text>
        <Divider />
        <Field
          name={'awsConfig.region'}
          render={renderSelectField(
            regionOptions, 'Region', undefined, undefined, undefined, undefined, this.onRegionChange
          )}
        />
        <Field
          name={'awsConfig.image_id'}
          render={renderSelectField(amiOptions, 'Image ID')}
        />
        <Field
          name={'awsConfig.security_group_ids'}
          render={renderSelectField(securityGroupsOptions, 'Security Group')}
        />
        <Field
          name={'awsConfig.subnet_id'}
          render={renderSelectField(subnetsOptions, 'Subnet')}
        />
        <Field
          name={'awsConfig.instance_type'}
          render={renderSelectField(instanceTypesOptions, 'Instance Type')}
        />
        <Field
            name='awsConfig.volume_size'
            render={renderNumericField('Volume Size', undefined, 0)}
          />
        <Field
          name={'awsConfig.key_name'}
          render={renderSelectField(keysOptions, 'Instance Access Key')}
        />
      </div>
    );
  }

  private renderBundleConfigForm = (props: FormikProps<CreateUpdateServerForm>): React.ReactNode => {
    const { bundle_options } = this.state;
    const channelsOptions = this.arrayToSelectListOptions(bundle_options?.channels || []);
    const releasesOptions = this.arrayToSelectListOptions(bundle_options?.releases || []);

    return (
      <div>
        <Text>BeezKeeper Config</Text>
        <Divider />
        <Field
          name={'beezkeeper_channel'}
          render={renderSelectField(
            channelsOptions, 'Channel', undefined, undefined, undefined, undefined, this.onChannelChange
          )}
        />
        <Field
          name={'beezkeeper_version'}
          render={renderSelectField(releasesOptions, 'Release')}
        />
      </div>
    );
  }

  private renderHeader = (): React.ReactNode => {
    const { customer, server } = this.props;
    const buttonTitle = this.editMode() ? 'Update Server' : 'Create Server';

    const serverLink = server?.id ? `/servers/${server.id}` : '';

    return (
      <div className='create-header editable-header'>
        <AnchorButton
          href={`/customers/${customer.id}${serverLink}`}
          text='Cancel'
          intent={Intent.PRIMARY}
          minimal
          />
        <H5>{buttonTitle}</H5>
        <Button
          text='Save'
          intent={Intent.PRIMARY}
          type='submit'
        />
      </div>
    );
  }

  private renderError = (): React.ReactNode => {
    const { error } = this.state;
    const bag = this.formRef.current?.getFormikBag();
    const bagError = bag ? !bag.isValid : false;

    const errorMessage = error
      ? 'Something went wrong while processing your request...'
      : bagError
        ? 'Your form has errors...'
        : '';


    return (!!error || bagError) && (
      <Callout
        intent={Intent.DANGER}
        className='margin-bottom-large'
      >
        {errorMessage}
      </Callout>
    );
  }

  private renderPasswordConfirmation = (): React.ReactNode => {
    return (
      <Alert
        className='password-confirmation'
        isOpen={this.state.showPasswordConfirmation}
        portalContainer={document.body}
        canEscapeKeyCancel={false}
        canOutsideClickCancel={false}
        confirmButtonText="Yes, I've copied the password"
        icon={IconNames.WARNING_SIGN}
        intent={Intent.WARNING}
        onConfirm={this.onPasswordConfirm}
      >
        <Text tagName='p'>
          <b>This password is not saved in IMS, this is the only time you will be able to copy this password.</b>
        </Text>
        <input name='password' readOnly className='bp3-input margin-bottom width-100' value={this.state.password}/>
        <Text tagName='p'>
          In order to SSH into Agents associated with this server you will need the above password
          to use in conjunction with the Agent SSH key.
        </Text>
        <Text tagName='p'>
          Create an entry in 1Password with this servers information including this password and
          fqdn as seen below. Once you've safely saved the password into 1Password you may click the button below.
        </Text>
        <img className='width-100 border-radius-5' src='/images/1password.png'/>
      </Alert>
    );
  }

  private onPasswordConfirm = () => {
    visit(`/customers/${this.props.customer.id}/servers/${this.state.serverId}`);
  }

  private onCreateServer = async (values: CreateUpdateServerForm) => {
    const { customer } = this.props;

    const server = {
      ...values,
      customer_id: customer.id
    };
    delete server.awsConfig;

    const awsOptions = {
      ...values.awsConfig,
      security_group_ids: [values.awsConfig?.security_group_ids],
      name: values.name
    };

    try {
      if (!!this.props.server) {
        await axiosClient.put(`/customers/${customer.id}/servers/${this.props.server.id}.json`,
          { server, aws_options: awsOptions });
        visit(`/customers/${customer.id}/servers/${this.props.server.id}`);
      } else {
        const { data } = await axiosClient.post(
          `/customers/${customer.id}/servers.json`,
          { server, aws_options: awsOptions }
        );

        window.history.pushState({ path: window.location.href }, '', window.location.href + '?server_id=' + data.id);
        this.setState({ ...this.state, serverId: data.id, password: data.password, showPasswordConfirmation: true });
      }

      this.setState({ error: false });
    } catch (error) {
      this.setState({ error: true });
      console.log('Something went wrong with your request.');
    }
  }

  private arrayToSelectListOptions = (data: string[]): SelectOption[] => {
    return data.map(option => {
      return { label: option, value: option };
    });
  }

  private onRegionChange = async (region: string) => {
    try {
      const { data } = await axiosClient.get(`/aws_options.json?region=${region}`);

      const bag = this.formRef.current?.getFormikBag();
      bag?.setFieldValue('awsConfig.subnet_id', data.subnets[0] || '');
      bag?.setFieldValue('awsConfig.image_id', data.amis[0] || '');
      bag?.setFieldValue('awsConfig.security_group_ids', data.security_groups[0] || '');
      bag?.setFieldValue('awsConfig.key_name',
        data.keys.includes('ims_key') ? 'ims_key' : data.keys[0]);

      this.setState({ aws_options: { ...data }, error: false });
    } catch (error) {
      this.setState({ error: true });
      console.log('Something went wrong while fetching AWS options for selected region.');
    }
  }

  private onChannelChange = async (channel: string) => {
    try {
      const { data } = await axiosClient.get(`/beezkeeper_bundles.json?channel=${channel}`);

      const bag = this.formRef.current?.getFormikBag();
      bag?.setFieldValue('beezkeeper_version', data.releases[0] || '');

      this.setState({ bundle_options: { ...data }, error: false });
    } catch (error) {
      this.setState({ error: true });
      console.log('Something went wrong while fetching AWS options for selected region.');
    }
  }

  private onServerTypeChange = async (serverType: string) => {
    const bag = this.formRef.current?.getFormikBag();
    const values = bag?.values;

    if ((serverType as ServerType) === ServerType.AWS) {
      bag?.setFieldValue('fqdn', `${values?.name || ''}.netbeezcloud.net`);
    } else {
      bag?.setFieldValue('fqdn', '');
    }
  }

  private onNameChange = (name: string) => {
    const bag = this.formRef.current?.getFormikBag();

    bag?.setFieldValue('fqdn', `${name}.netbeezcloud.net`);
  }

  private editMode = (): boolean => {
    return !!this.props.server;
  }
}
