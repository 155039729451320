export interface User {
  id: number;
  name: string;
  email: string;
  role: RoleType;
}

export enum RoleType {
  NONE = 'none',
  ADMIN = 'admin'
}
