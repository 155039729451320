import * as React from 'react';
import ReactTable, { CellInfo, Column } from 'react-table';
import {
  Alert,
  AnchorButton,
  Button,
  ButtonGroup,
  Card,
  Classes,
  Dialog,
  H5,
  Icon,
  Intent,
  IToastProps,
  Popover,
  PopoverInteractionKind,
  Text,
  TextArea
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { visit } from 'turbolinks';

import { axiosClient, getTableConfigs, TABLE_CONFIGS } from '../helpers';
import { Toast } from '../components';
import { License, LicenseType, Server } from '../models';

export interface LicensesProps {
  licenses: License[];
  server: Server;
}

export interface LicensesState {
  showNonActiveLicenses: boolean;
  showDeactivateConfirm: boolean;
  showTokenDecode: boolean;
  licenseIdToDeactivate: string | undefined;
  toastMessages: IToastProps[];
  token: string;
  tokenContents: string | undefined;
}

export class Licenses extends React.PureComponent<LicensesProps, LicensesState> {
  state: LicensesState = {
    showDeactivateConfirm: false,
    showNonActiveLicenses: false,
    showTokenDecode: false,
    licenseIdToDeactivate: undefined,
    toastMessages: [],
    token: '',
    tokenContents: undefined
  };

  render(): React.ReactNode {
    return (
      <Card className='server-agents'>
        {this.renderHeader()}
        {this.renderFilters()}
        {this.renderTable()}
        {this.renderDeactivateConfirmation()}
        {this.renderTokenDecode()}
        <Toast messages={this.state.toastMessages}/>
      </Card>
    );
  }

  private renderActionsCell = (row: CellInfo): React.ReactNode => {
    const { server } = this.props;
    const data = row.original as License;

    const currentDate = new Date().getTime();
    const dateCreated = new Date(data.created_at).getTime();
    const dateThreshold = dateCreated + (5 * 86400000);
    const dateExpired = new Date(data.expires_at).getTime();

    return (
      <div className='customer-actions-container'>
        <ButtonGroup>
          <AnchorButton
            href={`/servers/${server.id}/licenses/${data.id}`}
            icon={IconNames.EYE_OPEN}
            title='View License' />
          <AnchorButton
            disabled={
              currentDate > dateExpired ||
              data.license_type === LicenseType.LEGACY ||
              data.deactivated_at !== null
            }
            icon={IconNames.DUPLICATE}
            title='Copy License'
            onClick={this.onCopyClick(data.id)}
          />
          <AnchorButton
            disabled={(currentDate > dateExpired) || data.deactivated_at !== null }
            href={`/servers/${server.id}/licenses/${data.id}/edit`}
            icon={IconNames.EDIT}
            title='Edit Licenses'
          />
          <Button
            disabled={ data.deactivated_at !== null}
            icon={IconNames.DISABLE}
            title='Deactivate License'
            onClick={this.onDeactivateClick(data.id)}
          />
        </ButtonGroup>
      </div>
    );
  }

  private renderDeactivateConfirmation = (): React.ReactNode => {
    return (
      <Alert
        isOpen={this.state.showDeactivateConfirm}
        portalContainer={document.body}
        canEscapeKeyCancel={true}
        canOutsideClickCancel={true}
        cancelButtonText='Cancel'
        confirmButtonText='Deactivate License'
        icon={IconNames.DISABLE}
        intent={Intent.DANGER}
        onCancel={this.onDeactivateCancel}
        onConfirm={this.onDeactivateConfirm}
      >
        <Text>
          Do you want to deactivate this license?
        </Text>
      </Alert>
    );
  }

  private renderFilters = (): React.ReactNode => {
    const { showNonActiveLicenses } = this.state;
    const isActiveLabel = !showNonActiveLicenses ? 'Active Licenses' : 'Non-Active Licenses';

    return (
      <div className='filters-container space-between-center'>
        <div>
          <Popover
            interactionKind={PopoverInteractionKind.CLICK}
            position='bottom-left'
            usePortal={false}
          >
            <div className='filters-dropdown linked-dropdown'>
              <Text className='margin-right'>{isActiveLabel}</Text>
              <Icon icon='chevron-down'/>
            </div>
            {this.renderLinkedDropdown()}
          </Popover>
        </div>
      </div>
    );
  }

  private renderHeader = (): React.ReactNode => {
    const { licenses, server } = this.props;
    const length = licenses.length;

    return (
      <div className='card-header'>
        <div className='card-header-title'>
          <H5>Licenses</H5>
          <Text>{`Total: ${length} license${length > 1 ? 's' : ''}`}</Text>
        </div>
        <div>
          <Button
            text='Decode Token'
            icon={IconNames.CUBE}
            intent={Intent.NONE}
            onClick={this.onTokenDecodeClick}
           />
          { server.deactivated_at === null &&
            <Button
              text='New License'
              icon={IconNames.PLUS}
              onClick={this.onCreateLicenseClick}
              intent={Intent.PRIMARY}
            />
          }
        </div>
      </div>
    );
  }

  private renderLinkedDropdown = (): React.ReactNode => {
    const { showNonActiveLicenses } = this.state;

    return (
      <div className='filters-dropdown-content'>
        <div className={`container ${Classes.POPOVER_DISMISS}`}>
          <div className='item' onClick={this.onDropdownItemClick(false)}>
            Active
            {!showNonActiveLicenses && <Icon icon='tick'/>}
          </div>
          <div className='item' onClick={this.onDropdownItemClick(true)}>
            Non-Active
            {showNonActiveLicenses && <Icon icon='tick'/>}
          </div>
        </div>
      </div>
    );
  }

  private renderTable = (): React.ReactNode => {
    const { licenses } = this.props;

    let data;

    if (this.state.showNonActiveLicenses) {
      data = licenses.filter(x => (new Date().getTime() > Date.parse(x.expires_at) ||
        x.deactivated_at !== null));
    } else {
      data = licenses.filter(x => (new Date().getTime() < Date.parse(x.expires_at) &&
        x.deactivated_at === null));
    }

    return (
      <ReactTable
        data={data}
        columns={this.getColumns()}
        loading={false}
        getTableProps={getTableConfigs(TABLE_CONFIGS, 'table')}
        getTheadProps={getTableConfigs(TABLE_CONFIGS, 'thead')}
        getTheadThProps={getTableConfigs(TABLE_CONFIGS, 'theadTh')}
        getTrGroupProps={getTableConfigs(TABLE_CONFIGS, 'trGroup')}
        getTheadTrProps={getTableConfigs(TABLE_CONFIGS, 'trGroup')}
        getTdProps={getTableConfigs(TABLE_CONFIGS, 'td')}
      />
    );
  }

  private renderTokenDecode = (): React.ReactNode => {
    return (
      <Dialog
        className='token-decode'
        isOpen={this.state.showTokenDecode}
        portalContainer={document.body}
        canOutsideClickClose={true}
        icon={IconNames.CUBE}
        title='Decode Token'
        onClose={this.onTokenDecodeClose}
      >
        {this.state.tokenContents ? (
          <div className='bp3-dialog-body'>
            <TextArea readOnly rows={26} cols={60} value={JSON.stringify(this.state.tokenContents, null, '\t')}/>
          </div>
        ) : (
          <div className='bp3-dialog-body'>
            <Text>Paste token to decode</Text>
            <input className='width-100' onChange={this.onTokenInputChange} />
            <Button
              text='Decode'
              intent={Intent.PRIMARY}
              onClick={this.onDecodeClick}
              />
          </div>
        )}
      </Dialog>
    );
  }

  private getColumns = (): Column<License>[] => {
    return [
      {
        Header: 'Type',
        accessor: 'license_type',
        maxWidth: 60
      },
      {
        Header: 'Network Agents #',
        accessor: 'network_agents',
        maxWidth: 150
      },
      {
        Header: 'Remote Agents #',
        accessor: 'remote_worker_agents',
        maxWidth: 150
      },
      {
        Header: 'Created Date',
        accessor: 'created_at'
      },
      {
        Header: 'Expiration Date',
        accessor: 'expires_at'
      },
      {
        Cell: this.renderActionsCell,
        sortable: false,
        Header: 'Actions',
        headerClassName: 'no-arrow'
      }
    ];
  }

  private onCopyClick = (id: string) => () => {
    const { licenses } = this.props;
    const license = licenses.find(x => x.id === id);

    if (license?.jwt) {
      const textField = document.createElement('textarea');
      textField.innerText = license.jwt;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();

      const message: IToastProps = {
        icon: IconNames.TICK_CIRCLE,
        intent: Intent.SUCCESS,
        message: 'License Token Copied.',
        timeout: 3000
      };

      this.setState({ toastMessages: [message] });
    }
  }

  private onCreateLicenseClick = () => {
    const { id } = this.props.server;
    visit(`/servers/${id}/licenses/new`);
  }

  private onDeactivateClick = (id: string) => () => {
    this.setState({ showDeactivateConfirm: true, licenseIdToDeactivate: id });
  }

  private onDeactivateConfirm = async () => {
    try {
      const uri = `/servers/${this.props.server.id}/licenses/${this.state.licenseIdToDeactivate}/deactivate.json`;
      await axiosClient.put(uri);
      visit(`/servers/${this.props.server.id}?tab=licenses`);
    } catch (error) {
      this.setState({ showDeactivateConfirm: false, licenseIdToDeactivate: undefined });
    }
  }

  private onDecodeClick = async () => {
    try {
      const uri = `/licenses/decode.json`;
      const { data } = await axiosClient.post(uri, { token: this.state.token });

      this.setState({ tokenContents: data });
    } catch (error) {
      const message: IToastProps = {
        icon: IconNames.ERROR,
        intent: Intent.DANGER,
        message: 'Could not decoded token.',
        timeout: 3000
      };

      this.setState({ toastMessages: [message] });
    }
  }

  private onDeactivateCancel = () => {
    this.setState({ showDeactivateConfirm: false, licenseIdToDeactivate: undefined });
  }

  private onDropdownItemClick = (show: boolean) => () => {
    this.setState({ showNonActiveLicenses: show });
  }

  private onTokenDecodeClick = () => {
    this.setState({ showTokenDecode: true });
  }

  private onTokenDecodeClose = () => {
    this.setState({ showTokenDecode: false, token: '', tokenContents: undefined });
  }

  private onTokenInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ token: event.currentTarget.value });
  }
}
