import * as React from 'react';
import ReactTable, { CellInfo, Column } from 'react-table';
import { AnchorButton, Button, ButtonGroup, Callout, Card, H5, Intent, IToastProps, Text } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { visit } from 'turbolinks';

import { axiosClient, getTableConfigs, TABLE_CONFIGS } from '../helpers';

import { Agent, Server } from '../models';
import { Toast } from '../components';

export interface ServerAgentsProps {
  agents: Agent[];
  server: Server;
}

export interface ServerAgentsState {
  agents: Agent[];
  search: string;
  toastMessages: IToastProps[];
}

export class ServerAgents extends React.PureComponent<ServerAgentsProps, ServerAgentsState> {
  constructor(props: ServerAgentsProps) {
    super(props);

    this.state = {
      agents: props.agents,
      search: '',
      toastMessages: []
    };
  }

  render(): React.ReactNode {
    return (
      <Card className='server-agents'>
        {this.renderHeader()}
        {this.renderFilters()}
        {this.renderTable()}
        <Toast messages={this.state.toastMessages}/>
      </Card>
    );
  }

  private renderHeader = (): React.ReactNode => {
    const { agents } = this.state;

    return (
      <div className='card-header'>
        <div className='card-header-title'>
          <H5>Agents</H5>
          <Text>{`Total: ${agents.length} agents`}</Text>
        </div>
        <Button
          text='Link Agents'
          icon='plus'
          onClick={this.onLinkAgentClick}
          intent={Intent.PRIMARY}
        />
      </div>
    );
  }

  private renderFilters = (): React.ReactNode => {
    const { search } = this.state;

    return (
      <div className='filters-container'>
        <input
          className='bp3-input margin-right search-container'
          type='search'
          placeholder='Search'
          value={search}
          onChange={this.onSearchChange}
        />
      </div>
    );
  }

  private renderTable = (): React.ReactNode => {
    return (
      <ReactTable
        data={this.getFilteredData()}
        columns={this.getColumns()}
        loading={false}
        getTableProps={getTableConfigs(TABLE_CONFIGS, 'table')}
        getTheadProps={getTableConfigs(TABLE_CONFIGS, 'thead')}
        getTheadThProps={getTableConfigs(TABLE_CONFIGS, 'theadTh')}
        getTrGroupProps={getTableConfigs(TABLE_CONFIGS, 'trGroup')}
        getTheadTrProps={getTableConfigs(TABLE_CONFIGS, 'trGroup')}
        getTdProps={getTableConfigs(TABLE_CONFIGS, 'td')}
      />
    );
  }

  private renderActionsCell = (row: CellInfo): React.ReactNode => {
    const data = row.original as Agent;

    return (
      <div className='customer-actions-container'>
        <ButtonGroup>
          <AnchorButton
            href={`/agents/${data.id}`}
            icon={IconNames.EYE_OPEN}
            title='View Agent' />
          <AnchorButton
            // disabled={(currentDate > dateExpired) || data.deactivated_at !== null }
            href={`/agents/${data.id}/edit`}
            icon={IconNames.EDIT}
            title='Edit Agent'
          />
          <Button
            icon={IconNames.UNLOCK}
            title='Unlink Agent'
            onClick={this.onUnlinkClick(data)}
           />
           {/* <Button
            icon={IconNames.UNLOCK}
            title='RMA'
            onClick={this.onUnlinkClick(data)}
           /> */}
        </ButtonGroup>
      </div>
    );
  }

  private onLinkAgentClick = () => {
    const { server } = this.props;

    visit(`/agents?selected_server_id=${server.id}`);
  }

  private onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ search: event.currentTarget.value });
  }

  private getFilteredData = () => {
    const { search, agents } = this.state;

    let agentsFiltered = [...agents];

    if (search.length > 0) {
      // tslint:disable-next-line: ter-max-len
      agentsFiltered = agentsFiltered.filter(a => a.id.toString().includes(search) || a.id.includes(search) || a.mac_address.includes(search));
    }

    return agentsFiltered;
  }

  private onUnlinkClick = (agent: Agent) => async () => {
    try {
      await axiosClient.put(`/agents/${agent.id}.json`, { agent: { server_uuid: null }});

      // Update agents data
      const newAgents = this.state.agents.filter(a => a.id !== agent.id);

      const message: IToastProps = {
        icon: IconNames.TICK_CIRCLE,
        intent: Intent.SUCCESS,
        message: 'Agent was unlinked successfully.',
        timeout: 3000
      };

      this.setState({ agents: newAgents, toastMessages: [message] });
    } catch (e) {
      const message: IToastProps = {
        icon: IconNames.ERROR,
        intent: Intent.DANGER,
        message: 'Something went wrong while unlinking agent.',
        timeout: 6000
      };

      this.setState({ toastMessages: [message] });
    }
  }

  private getColumns = (): Column[] => {
    return [
      {
        Header: 'ID',
        accessor: 'id',
        maxWidth: 270
      },
      {
        Header: 'MAC',
        accessor: 'mac_address',
        maxWidth: 120
      },
      {
        Header: 'Model',
        accessor: 'model'
      },
      {
        id: 'poe',
        Header: 'PoE',
        accessor: d => d.poe ? 'Yes' : 'No',
        width: 60
      },
      {
        Cell: this.renderActionsCell,
        sortable: false,
        Header: 'Actions',
        headerClassName: 'no-arrow'
      }
    ];
  }
}
