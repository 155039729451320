import * as React from 'react';
import ReactTable, { CellInfo, Column } from 'react-table';
import {
  AnchorButton,
  Button,
  Card,
  Dialog,
  H5,
  Intent,
  IToastProps,
  Text
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { axiosClient, getTableConfigs, TABLE_CONFIGS } from '../helpers';
import { Toast } from '../components';
import { Server, ServerDownload } from '../models';

export interface DownloadsProps {
  downloads: ServerDownload[];
  server: Server;
}

export interface DownloadsState {
  downloads: ServerDownload[];
  password: string | undefined;
  showPPKGenerate: boolean;
  toastMessages: IToastProps[];
}

export class Downloads extends React.PureComponent<DownloadsProps, DownloadsState> {
  state: DownloadsState = {
    downloads: this.props.downloads,
    password: undefined,
    showPPKGenerate: false,
    toastMessages: []
  };

  render(): React.ReactNode {
    return (
      <Card className='server-downloads'>
        {this.renderHeader()}
        {this.renderTable()}
        {this.renderPPKGenerate()}
        <Toast messages={this.state.toastMessages} />
      </Card>
    );
  }

  private renderActionsCell = (row: CellInfo): React.ReactNode => {
    const { server } = this.props;
    const data = row.original as ServerDownload;

    return (
      <div className='downloads-actions-container'>
        {data.md5 === null && data.name.includes('ppk') ? (
          <Button
            icon={IconNames.PLUS}
            title='Generate PPK'
            onClick={this.onNewPPKClick}
          />
        ) : (
          <AnchorButton
            disabled={server.deactivated_at !== null || data.md5 === null}
            href={`/servers/${server.id}/downloads?file=${data.name}`}
            icon={IconNames.DOWNLOAD}
            title='Download File'
            download={data.name}
          />
        )}
      </div>
    );
  }

  private renderHeader = (): React.ReactNode => {
    const { downloads, server } = this.props;
    const length = downloads.length;

    return (
      <div className='card-header'>
        <div className='card-header-title'>
          <H5>Downloads</H5>
          <Text>{`Total: ${length} download${length > 1 ? 's' : ''}`}</Text>
        </div>
      </div>
    );
  }

  private renderTable = (): React.ReactNode => {
    const { downloads } = this.state;

    return (
      <ReactTable
        data={downloads}
        columns={this.getColumns()}
        loading={false}
        getTableProps={getTableConfigs(TABLE_CONFIGS, 'table')}
        getTheadProps={getTableConfigs(TABLE_CONFIGS, 'thead')}
        getTheadThProps={getTableConfigs(TABLE_CONFIGS, 'theadTh')}
        getTrGroupProps={getTableConfigs(TABLE_CONFIGS, 'trGroup')}
        getTheadTrProps={getTableConfigs(TABLE_CONFIGS, 'trGroup')}
        getTdProps={getTableConfigs(TABLE_CONFIGS, 'td')}
      />
    );
  }

  private getColumns = (): Column<ServerDownload>[] => {
    return [
      {
        Header: 'Name',
        accessor: 'name',
        maxWidth: 120
      },
      {
        Header: 'MD5',
        accessor: 'md5',
        maxWidth: 300
      },
      {
        Cell: this.renderActionsCell,
        sortable: false,
        Header: 'Actions',
        headerClassName: 'no-arrow'
      }
    ];
  }

  private renderPPKGenerate = (): React.ReactNode => {
    return (
      <Dialog
        className='token-decode'
        isOpen={this.state.showPPKGenerate}
        portalContainer={document.body}
        canOutsideClickClose={true}
        icon={IconNames.PLUS}
        title='Generate Agent SSH PPK'
        onClose={this.onPPKCloseClick}
      >
        <div className='bp3-dialog-body'>
          <Text>Agent SSH Key Password</Text>
          <input className='width-100' type='password' onChange={this.onPasswordChange} />
          <Button
            text='Generate'
            intent={Intent.PRIMARY}
            onClick={this.onPPKGenerateClick}
            />
        </div>
      </Dialog>
    );
  }

  private onNewPPKClick = () => {
    this.setState({ showPPKGenerate: true });
  }

  private onPPKCloseClick = () => {
    this.setState({ showPPKGenerate: false });
  }

  private onPPKGenerateClick = async () => {
    try {
      const uri = `/servers/${this.props.server.id}/generate_ppk.json`;
      const { data } = await axiosClient.post(uri, { password: this.state.password });

      const message: IToastProps = {
        icon: IconNames.TICK_CIRCLE,
        intent: Intent.SUCCESS,
        message: 'PPK generated successfully.',
        timeout: 3000
      };

      this.setState({ downloads: data, showPPKGenerate: false, toastMessages: [message] });
    } catch (error) {
      const message: IToastProps = {
        icon: IconNames.ERROR,
        intent: Intent.DANGER,
        message: 'PPK could not be generated due to incorrect password or error.',
        timeout: 3000
      };

      this.setState({ toastMessages: [message] });
    }
  }

  private onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: event.currentTarget.value });
  }
}
