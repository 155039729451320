import { Select, SelectOption } from '../Select';
import { FieldProps } from 'formik';
import * as React from 'react';
import { FormGroup, Intent } from '@blueprintjs/core';

export const renderSelectField = (
  options: SelectOption[],
  label?: string,
  helpText?: string,
  labelAbove?: boolean,
  disabled?: boolean,
  onChange?: (formikProps: FieldProps, newValue: string) => void,
  callback?: (valueAsString: string) => void
) =>
  (props: FieldProps): React.ReactNode => {
    const { field, form } = props;
    const { setFieldValue, setFieldTouched, errors, touched } = form;
    const setFieldValueFn = (valueAsString: string) => {
      if (onChange) {
        onChange(props, valueAsString);
      } else {
        setFieldValue(field.name, valueAsString);
      }

      if (callback) {
        callback(valueAsString);
      }

      setFieldTouched(field.name);
    };

    const onBlur = () => setFieldTouched(field.name);
    const error = touched[field.name] && errors[field.name];

    return (
      <FormGroup
        label={label ? `${label}:` : ''}
        inline={!labelAbove}
        intent={Intent.NONE}
        className={`${labelAbove ? 'margin-right' : 'form-group'}`}
      >
        <Select
          {...field}
          initialSelectedValue={field.value}
          value={field.value}
          options={options}
          disabled={disabled}
          onChange={setFieldValueFn}
          onBlur={onBlur}
        />
        {!!helpText && <div className='input-helper'>{helpText}</div>}
        {!!error && <div className='input-error'>{error}</div>}
      </FormGroup>
    );
  };
