import { EntityTimestamps } from './EntityTimestamps';

export interface Agent extends EntityTimestamps {
  id: string;
  description?: string;
  is_rma: boolean;
  mac_address: string;
  model: string;
  rma_category?: string;
  rma_description?: string;
  server_id?: string;
  poe: boolean;
  created_at: string;
  updated_at: string;
  last_seen_at: string;
}

export interface AgentEvent {
  id: string;
  event_type: string;
  rma_category: string;
  description: string;
  occurred_at: string;
  created_at: string;
  updated_at: string;
  agent_id: string;
  replacement_agent_id: string;
}

export enum EventTypes {
  RMA = 'rma',
  LINKING = 'linking',
  REGISTER = 'register',
  QUERY = 'query'
}

export enum RMACategories {
  DISK_CORRUPTION = 'disk_corruption',
  POE = 'poe',
  EOL = 'eol',
  SOFTWARE_RELATED = 'software_related',
  USER_RELATED = 'user_related',
  OTHER = 'other'
}