import * as React from 'react';
import ReactTable, { CellInfo, Column } from 'react-table';
import {
  AnchorButton,
  ButtonGroup,
  Card,
  H5,
  Text
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { getTableConfigs, TABLE_CONFIGS } from '../helpers';
import { ExpiringLicense } from '../models';

export interface LicensesProps {
  licenses: ExpiringLicense[];
}

export class ExpiringLicenses extends React.PureComponent<LicensesProps> {
  render(): React.ReactNode {
    return (
      <Card className='expiring-licenses'>
        {this.renderHeader()}
        {this.renderTable()}
      </Card>
    );
  }

  private renderActionsCell = (row: CellInfo): React.ReactNode => {
    const data = row.original as ExpiringLicense;

    return (
      <div className='license-actions-container'>
        <ButtonGroup>
          <AnchorButton
            href={`/servers/${data.server_id}?tab=licenses`}
            icon={IconNames.EYE_OPEN}
            title='View Licenses' />
        </ButtonGroup>
      </div>
    );
  }

  private renderHeader = (): React.ReactNode => {
    const { licenses } = this.props;
    const length = licenses.length;

    return (
      <div className='card-header'>
        <div className='card-header-title'>
          <H5>Expiring Licenses <span>(next 30 days)</span></H5>
          <Text>{`Total: ${length} license${length > 1 ? 's' : ''}`}</Text>
        </div>
      </div>
    );
  }

  private renderTable = (): React.ReactNode => {
    const { licenses } = this.props;

    return (
      <ReactTable
        data={licenses}
        columns={this.getColumns()}
        loading={false}
        showPagination={false}
        pageSize={licenses.length}
        getTableProps={getTableConfigs(TABLE_CONFIGS, 'table')}
        getTheadProps={getTableConfigs(TABLE_CONFIGS, 'thead')}
        getTheadThProps={getTableConfigs(TABLE_CONFIGS, 'theadTh')}
        getTrGroupProps={getTableConfigs(TABLE_CONFIGS, 'trGroup')}
        getTheadTrProps={getTableConfigs(TABLE_CONFIGS, 'trGroup')}
        getTdProps={getTableConfigs(TABLE_CONFIGS, 'td')}
      />
    );
  }

  private getColumns = (): Column<ExpiringLicense>[] => {
    return [
      {
        Header: 'Customer',
        accessor: 'customer_name',
        maxWidth: 225
      },
      {
        Header: 'Server ID',
        accessor: 'server_id',
        maxWidth: 280
      },
      {
        Header: 'Type',
        accessor: 'license_type',
        maxWidth: 80
      },
      {
        Header: 'Expiration Date',
        accessor: 'expires_at',
        maxWidth: 200
      },
      {
        Cell: this.renderActionsCell,
        sortable: false,
        Header: 'Actions',
        headerClassName: 'no-arrow',
        maxWidth: 60
      }
    ];
  }
}
