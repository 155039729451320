import * as React from 'react';
import { Card, H2, H5, Icon, Intent, IToastProps, Text, TextArea } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { axiosClient } from '../helpers';

import { Toast } from '../components';
import { Customer, License as LicenseModel, LicenseType } from '../models';

export interface LicenseProp {
  license: LicenseModel;
  customer: Customer;
}

export interface LicenseState {
  toastMessages: IToastProps[];
}

export class License extends React.PureComponent<LicenseProp> {
  state: LicenseState = {
    toastMessages: []
  };

  render(): React.ReactNode {
    const { customer, license } = this.props;

    return (
      <div>
        <H2>License</H2>
        <Card className='license-view'>
          <div className='license-details-container'>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>License ID:</Text>
              <Text>{license.id}</Text>
            </div>
            <div className='flex-row'>
            <Text className='margin-right-small details-label'>Customer:</Text>
              <a href={`/customers/${customer.id}`}>{customer.name}</a>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Server:</Text>
              <a href={`/servers/${license.server_id}`}>{license.server_id}</a>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>License Type:</Text>
              <Text>{license.license_type}</Text>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Hardware ID:</Text>
              <Text>{license.hardware_id}</Text>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Expires At:</Text>
              <Text>{license.expires_at}</Text>
            </div>
            {(
              this.canCopyLicense()
              ) && (
              <div className='flex-row'>
                <Text className='margin-right-small details-label'>License Token:</Text>
                <input readOnly value={license.jwt}/>
                <Icon icon={IconNames.DUPLICATE} onClick={this.onCopyClick}/>
              </div>
            )}
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Created At:</Text>
              <Text>{license.created_at}</Text>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Updated At:</Text>
              <Text>{license.updated_at}</Text>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Deactivated At:</Text>
              <Text>{license.deactivated_at}</Text>
            </div>
            { license.license_type !== LicenseType.LEGACY ? (
              <div>
                <div className='flex-row license-details-section'>
                  <H5>Network Agents</H5>
                </div>
                <div className='flex-row'>
                  <Text className='margin-right-small details-label'>Agent Limit:</Text>
                  <Text>{license.agent_licenses.network_agents.agent_limit}</Text>
                </div>
                <div className='flex-row'>
                  <Text className='margin-right-small details-label'>Target Test Limit:</Text>
                  <Text>{license.agent_licenses.network_agents.target_test_limit}</Text>
                </div>
                <div className='flex-row'>
                  <Text className='margin-right-small details-label'>Scheduled Test Limit:</Text>
                  <Text>{license.agent_licenses.network_agents.scheduled_test_limit}</Text>
                </div>
                <div className='flex-row license-details-section'>
                  <H5>Remote Worker Agents</H5>
                </div>
                <div className='flex-row'>
                  <Text className='margin-right-small details-label'>Agent Limit:</Text>
                  <Text>{license.agent_licenses.remote_worker_agents.agent_limit}</Text>
                </div>
                <div className='flex-row'>
                  <Text className='margin-right-small details-label'>Target Test Limit:</Text>
                  <Text>{license.agent_licenses.remote_worker_agents.target_test_limit}</Text>
                </div>
                <div className='flex-row'>
                  <Text className='margin-right-small details-label'>Scheduled Test Limit:</Text>
                  <Text>{license.agent_licenses.remote_worker_agents.scheduled_test_limit}</Text>
                </div>
              </div>
            ) : (
              <div>
                <div className='flex-row license-details-section'>
                  <H5>Legacy License</H5>
                </div>
                <TextArea readOnly rows={15} cols={45} value={JSON.stringify(license.agent_licenses, null, '\t')}/>
              </div>
            )}
          </div>
        </Card>
        <Toast messages={this.state.toastMessages} />
      </div>
    );
  }

  private onCopyClick = () => {
    const { license } = this.props;

    if (license?.jwt) {
      const textField = document.createElement('textarea');
      textField.innerText = license.jwt;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();

      const message: IToastProps = {
        icon: IconNames.TICK_CIRCLE,
        intent: Intent.SUCCESS,
        message: 'License Token Copied.',
        timeout: 3000
      };

      this.setState({ toastMessages: [message] });
    }
  }

  private canCopyLicense = (): boolean => {
    const { license } = this.props;

    const currentDate = new Date().getTime();
    const dateExpires = new Date(license.expires_at).getTime();

    return (currentDate < dateExpires) &&
      license.deactivated_at === null && license.license_type !== LicenseType.LEGACY;
  }
}
