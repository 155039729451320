import React, { PureComponent } from 'react';
import { Callout, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { ExpiringLicense, RecentServer, RoleType, User } from '../models';
import { ExpiringLicenses } from './ExpiringLicenses';
import { RecentServers } from './RecentServers';

interface DashboardProps {
  currentUser?: User;
  licenses: ExpiringLicense[];
  servers: RecentServer[];
}

export class Dashboard extends PureComponent<DashboardProps> {
  render (): React.ReactNode {
    const { currentUser } = this.props;
    const isLoggedIn = !!currentUser;
    const hasAdminAccess = !!currentUser && currentUser.role === RoleType.ADMIN;

    return (
      <div className='flex flex-column'>
        {isLoggedIn && (
          hasAdminAccess ? this.renderAdmin() : this.renderNonAdmin()
        )}
      </div>
    );
  }

  private renderAdmin = (): React.ReactNode => {
    return (
      <div className='dashboard-view'>
        <Callout
          className='align-center'
          icon={IconNames.THUMBS_UP}
          intent={Intent.PRIMARY}
          title={'Hello ' + this.props.currentUser?.name}>
          You are logged in as an administrator.  You can manage customers and their servers as well as link agents.
        </Callout>
        <div className='flex'>
          <ExpiringLicenses licenses={this.props.licenses} />
          <RecentServers servers={this.props.servers} />
        </div>
      </div>
    );
  }

  private renderNonAdmin = (): React.ReactNode => {
    return (
      <Callout
        className='align-center'
        icon={IconNames.WARNING_SIGN}
        intent={Intent.WARNING}
        title='Permissions Required'>
        You have successfully logged into IMS, but you currently do not have any permissions to any functionality.
        Please contact the development to request the approprate level of permissions.
      </Callout>
    );
  }
}
