import React, { PureComponent } from 'react';
import { Callout, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

export class Home extends PureComponent {
  render (): React.ReactNode {

    return (
      <div className='flex flex-column'>
        {this.renderUnauthenticated()}
      </div>
    );
  }

  private renderUnauthenticated = (): React.ReactNode => {
    return (
      <Callout className='align-center' icon={IconNames.HAND} intent={Intent.PRIMARY} title='Hello there'>
        Login to IMS using the 'Sign In' button above.
      </Callout>
    );
  }
}
