import * as React from 'react';
import ReactTable, { Column } from 'react-table';
import { Card, H5, IToastProps, Text } from '@blueprintjs/core';

import { getTableConfigs, TABLE_CONFIGS } from '../helpers';

import { Agent, AgentEvent } from '../models';
import { Toast } from '.';

export interface AgentActivityEventsProps {
  events: AgentEvent[];
  eventTypes: { [key: string]: string };
  agent: Agent;
}

export interface AgentActivityEventsState {
  events: AgentEvent[];
  search: string;
  toastMessages: IToastProps[];
}

export class AgentActivityEvents extends React.PureComponent<AgentActivityEventsProps, AgentActivityEventsState> {
  constructor(props: AgentActivityEventsProps) {
    super(props);

    this.state = {
      events: props.events,
      search: '',
      toastMessages: []
    };
  }

  render(): React.ReactNode {
    return (
      <Card className='server-agents'>
        {this.renderHeader()}
        {this.renderFilters()}
        {this.renderTable()}
        <Toast messages={this.state.toastMessages}/>
      </Card>
    );
  }

  private renderHeader = (): React.ReactNode => {
    const { events } = this.state;

    return (
      <div className='card-header'>
        <div className='card-header-title'>
          <H5>Agent Activity</H5>
          <Text>Last 100 events</Text>
        </div>
      </div>
    );
  }

  private renderFilters = (): React.ReactNode => {
    const { search } = this.state;

    return (
      <div className='filters-container'>
        <input
          className='bp3-input margin-right search-container'
          type='search'
          placeholder='Search'
          value={search}
          onChange={this.onSearchChange}
        />
      </div>
    );
  }

  private renderTable = (): React.ReactNode => {
    return (
      <ReactTable
        data={this.getFilteredData()}
        columns={this.getColumns()}
        loading={false}
        getTableProps={getTableConfigs(TABLE_CONFIGS, 'table')}
        getTheadProps={getTableConfigs(TABLE_CONFIGS, 'thead')}
        getTheadThProps={getTableConfigs(TABLE_CONFIGS, 'theadTh')}
        getTrGroupProps={getTableConfigs(TABLE_CONFIGS, 'trGroup')}
        getTheadTrProps={getTableConfigs(TABLE_CONFIGS, 'trGroup')}
        getTdProps={getTableConfigs(TABLE_CONFIGS, 'td')}
      />
    );
  }

  private onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ search: event.currentTarget.value });
  }

  private getFilteredData = () => {
    const { search, events } = this.state;
    const { eventTypes } = this.props;

    let eventsFiltered = [...events];

    if (search.length > 0) {
      // tslint:disable-next-line: ter-max-len
      eventsFiltered = eventsFiltered.filter(e => {
        return e.description.toLowerCase().includes(search.toLowerCase()) ||
          eventTypes[e.event_type].toLowerCase().includes(search.toLowerCase());
      });
    }

    return eventsFiltered;
  }

  private getColumns = (): Column[] => {
    const { eventTypes } = this.props;

    return [
      {
        Header: 'Timestamp',
        accessor: 'occurred_at',
        maxWidth: 270
      },
      {
        id: 'event_type',
        Header: 'Type',
        accessor: d => eventTypes[d.event_type],
        maxWidth: 180
      },
      {
        Header: 'Description',
        accessor: 'description'
      }
    ];
  }
}
