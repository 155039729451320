import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import { AnchorButton, Button, H5, Intent, IToastProps } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as yup from 'yup';
import { visit } from 'turbolinks';

import { axiosClient } from '../helpers';
import { renderTextField } from './form';

import { Customer } from '../models';
import { Toast } from '../components';

const phoneNumberRegex = /([+]?\d{1,2})?(\d{3}){2}\d{4}/;
// tslint:disable-next-line: ter-max-len
const uriRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

const validationSchema = yup.object().shape({
  name: yup.string()
    .required('Name is required')
    .max(255, 'First name must be less than 256 characters.'),
  description: yup.string()
    .max(255, 'Description must be less than 256 characters.'),
  contact_name: yup.string()
    .required('Contact name is required')
    .max(255, 'Contact name must be less than 256 characters.'),
  contact_phone_number: yup.string()
    .required('Contact phone number is required')
    .matches(phoneNumberRegex, 'Contact phone number format is incorrect.'),
  contact_email: yup.string()
    .required('Email is required')
    .email('Invalid email address.')
    .max(255, 'Email must be less than 256 characters.'),
  address: yup.string()
    .required('Address is required')
    .max(255, 'Address must be less than 256 characters.'),
  salesforce_url: yup.string()
    .matches(uriRegex, 'URL must be valid.')
});

type CreateUpdateCustomerForm = Omit<Customer,
  'id' | 'uuid' | 'company_id' | 'deactivated_at' | 'created_at' | 'updated_at'>;

export interface CreateUpdateCustomerProps {
  customer?: Customer;
}

export interface CreateUpdateCustomerState {
  toastMessages: IToastProps[];
}

export class CreateUpdateCustomer extends React.PureComponent<CreateUpdateCustomerProps, CreateUpdateCustomerState> {
  state: CreateUpdateCustomerState = {
    toastMessages: []
  };

  render(): React.ReactNode {
    const { customer } = this.props;

    const initialValues: CreateUpdateCustomerForm = {
      name: customer ? customer.name : '',
      description: customer?.description ? customer.description : '',
      contact_name: customer ? customer.contact_name : '',
      contact_email: customer ? customer.contact_email : '',
      contact_phone_number: customer ? customer.contact_phone_number : '',
      address: customer ? customer.address : '',
      salesforce_url: customer?.salesforce_url ? customer.salesforce_url : ''
    };

    return (
      <div className='flex'>
        <Formik
          onSubmit={this.onCreateCustomer}
          render={this.renderForm}
          initialValues={initialValues}
          validationSchema={validationSchema}
        />
        <Toast messages={this.state.toastMessages}/>
      </div>
    );
  }

  private renderForm = (): React.ReactNode => {
    return (
      <Form className='create-customer'>
        {this.renderHeader()}
        <div className='create-customer-fields-container'>
          <Field
            name='name'
            render={renderTextField('Name')}
          />
          <Field
            name='description'
            render={renderTextField('Description')}
          />
          <Field
            name='address'
            render={renderTextField('Address')}
          />
          <Field
            name='contact_name'
            render={renderTextField('Contact Name')}
          />
          <Field
            name='contact_email'
            render={renderTextField('Contact Email')}
          />
          <Field
            name='contact_phone_number'
            render={renderTextField(
              'Contact Phone',
              'Must be in following format, country code optional for US numbers: +15555555555'
            )}
          />
          <Field
            name='salesforce_url'
            render={renderTextField('Salesforce URL')}
          />
        </div>
      </Form>
    );
  }

  private renderHeader = (): React.ReactNode => {
    const { customer } = this.props;
    const buttonTitle = customer ? 'Update Customer' : ' Create Customer';

    return (
      <div className='create-header editable-header'>
        <AnchorButton
          href={`/customers${customer ? `/${customer.id}` : '' }`}
          text='Cancel'
          intent={Intent.PRIMARY}
          minimal
        />
        <H5>{customer ? 'Update Customer' : ' Create Customer'}</H5>
        <Button
          text='Save'
          intent={Intent.PRIMARY}
          type='submit'
        />
      </div>
    );
  }

  private onCreateCustomer = async (values: CreateUpdateCustomerForm) => {
    const { customer } = this.props;
    try {
      if (!!customer) {
        await axiosClient.put(`/customers/${customer.id}.json`, { customer: values });
        visit(`/customers/${customer.id}`);
      } else {
        const { data } = await axiosClient.post('/customers.json', { customer: values });
        visit(`/customers/${data.id}`);
      }

    } catch (error) {
      const message: IToastProps = {
        icon: IconNames.ERROR,
        intent: Intent.DANGER,
        message: `Something went wrong. Could not ${!!this.props.customer ? 'update' : 'create'} customer.`,
        timeout: 6000
      };

      this.setState({ toastMessages: [message] });
    }
  }
}
