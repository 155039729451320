import * as React from 'react';
import { Alert, AnchorButton, Button, Card, H2, Intent, IToastProps, Text } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Servers } from './Servers';
import { Toast } from './Toast';

import { axiosClient } from '../helpers';

import { Customer as CustomerModel, Server } from '../models';
import { visit } from 'turbolinks';

export interface CustomerProps {
  customer: CustomerModel;
  servers: Server[];
}

export interface CustomerState {
  showDeactivateConfirmation: boolean;
  showDeleteConfirmation: boolean;
  toastMessages: IToastProps[];
}

export class Customer extends React.PureComponent<CustomerProps, CustomerState> {
  state: CustomerState = {
    showDeactivateConfirmation: false,
    showDeleteConfirmation: false,
    toastMessages: []
  };

  render(): React.ReactNode {
    const { servers, customer } = this.props;
    return (
      <div className='customer-view'>
        <div className='flex'>
          <div className='flex flex-column customer-details-container'>
            <H2>{`Customer - ${customer.name}`}</H2>
            {this.renderCustomerDetails()}
          </div>
          <div className='flex flex-column customer-tabs-container'>
            <Servers servers={servers} customer={customer}/>
            {this.renderDeactivateConfirmation()}
            {this.renderDeleteConfirmation()}
          </div>
        </div>
        <Toast messages={this.state.toastMessages}/>
      </div>
    );
  }

  private renderCustomerDetails = (): React.ReactNode => {
    const { customer } = this.props;

    return (
      <Card className='customer-details'>
        <div className='details'>
          <div className='flex flex-column'>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>ID:</Text>
              <Text>{customer.id}</Text>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Internal ID:</Text>
              <Text>{customer.company_id}</Text>
            </div>
            {customer.salesforce_url && (
              <div className='flex-row'>
                <Text className='margin-right-small details-label'>Salesforce:</Text>
                <AnchorButton
                  minimal={true}
                  icon={IconNames.SHARE}
                  text='View on Salesforce'
                  href={customer.salesforce_url}
                  target='_blank'
                />
              </div>
            )}
            {customer.description && (
              <div className='flex-row'>
                <Text className='margin-right-small details-label'>Description:</Text>
                <Text>{customer.description}</Text>
              </div>
            )}
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Contact Name:</Text>
              <Text>{customer.contact_name}</Text>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Contact Email:</Text>
              <Text>{customer.contact_email}</Text>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Contact Number:</Text>
              <Text>{customer.contact_phone_number}</Text>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Address:</Text>
              <Text>{customer.address}</Text>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Created At:</Text>
              <Text>{customer.created_at}</Text>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Updated At:</Text>
              <Text>{customer.updated_at}</Text>
            </div>
            <div className='flex-row'>
              <Text className='margin-right-small details-label'>Status:</Text>
              <Text>{!!customer.deactivated_at ? 'Closed' : 'Open'}</Text>
            </div>
          </div>
        </div>
        <div className='customer-actions'>
          {!customer.deactivated_at ? (
            <div>
              <AnchorButton
                text='Edit Customer'
                icon={IconNames.EDIT}
                href={`/customers/${customer.id}/edit`}
                intent={Intent.PRIMARY}
               />
              <Button
                text='Deactivate Customer'
                icon={IconNames.DISABLE}
                onClick={this.onDeactivateClick}
                intent={Intent.DANGER}
              />
            </div>
          ) : (
            <Button
              text='Delete Customer'
              icon={IconNames.TRASH}
              onClick={this.onDeleteClick}
              intent={Intent.DANGER}
            />
          )}
        </div>
      </Card>
    );
  }

  private renderDeactivateConfirmation = (): React.ReactNode => {
    return (
      <Alert
        isOpen={this.state.showDeactivateConfirmation}
        portalContainer={document.body}
        canEscapeKeyCancel={true}
        canOutsideClickCancel={true}
        cancelButtonText='Cancel'
        confirmButtonText='Deactivate Customer'
        icon={IconNames.DISABLE}
        intent={Intent.DANGER}
        onCancel={this.onDeactivateCancel}
        onConfirm={this.onDeactivateConfirm}
      >
        <Text>
          Are you sure you want to deactivate customer <b>{this.props.customer.name}</b>?
        </Text>
      </Alert>
    );
  }

  private renderDeleteConfirmation = (): React.ReactNode => {
    return (
      <Alert
        isOpen={this.state.showDeleteConfirmation}
        portalContainer={document.body}
        canEscapeKeyCancel={true}
        canOutsideClickCancel={true}
        cancelButtonText='Cancel'
        confirmButtonText='Delete Customer'
        icon={IconNames.TRASH}
        intent={Intent.DANGER}
        onCancel={this.onDeleteCancel}
        onConfirm={this.onDeleteConfirm}
      >
        <Text>
          All customer data will be erased including servers and licenses.
          Are you sure you want to delete <b>{this.props.customer.name}</b>?
        </Text>
      </Alert>
    );
  }

  private onDeactivateClick = () => {
    this.setState({ showDeactivateConfirmation: true });
  }

  private onDeleteClick = () => {
    this.setState({ showDeleteConfirmation: true });
  }

  private onDeactivateCancel = () => {
    this.setState({ showDeactivateConfirmation: false });
  }

  private onDeleteCancel = () => {
    this.setState({ showDeleteConfirmation: false });
  }

  private onDeactivateConfirm = async () => {
    await axiosClient.put(`/customers/${this.props.customer.id}/deactivate.json`);

    window.location.reload(false);
  }

  private onDeleteConfirm = async () => {
    try {
      await axiosClient.delete(`/customers/${this.props.customer.id}.json`);
      visit('/customers');
    } catch (error) {
      const message: IToastProps = {
        icon: IconNames.ERROR,
        intent: Intent.DANGER,
        message: `Something went wrong. Could not delete customer, please check if the customer has active servers.`,
        timeout: 6000
      };

      this.setState({ showDeleteConfirmation: false, toastMessages: [message] });
    }
  }
}
