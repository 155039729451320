import React, { PureComponent } from 'react';
import { IToastProps, Position, Toast as ToastMessage, Toaster } from '@blueprintjs/core';


interface ToastProps {
  messages: IToastProps[];
}

interface ToastState {
  toasts: IToastProps[];
}

export class Toast extends PureComponent<ToastProps, ToastState> {
  private toaster: React.RefObject<Toaster>;

  constructor(props: { messages: [] }) {
    super(props);

    this.state = { toasts: [] };
    this.toaster = React.createRef<Toaster>();
  }

  componentDidUpdate (): void {
    const toaster = this.toaster.current;

    if (toaster) {
      this.props.messages.map(x => toaster.show(x));
    }
  }

  render (): React.ReactNode {
    return (
      <Toaster position={Position.TOP} usePortal={false} ref={this.toaster}>
        {this.state.toasts.map((toast, i) => <ToastMessage key={`toast-${i}`} {...toast}/>)}
      </Toaster>
    );
  }
}
